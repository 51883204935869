import { Form, Formik } from "formik";
import { usePostApiV1CoreByAccountTypeRegisterMutation } from "store/api/account";
import { RegisterOwnerStepValidation } from "utils/ValidationScheme";
import { Box, Flex, Text, Link } from "@chakra-ui/react";
import { RegisterStepEnum } from "configs/login";
import { useEffect, useState } from "react";
import {
  usePostApiV1CoreAuthEmailResendOtpMutation,
  usePostApiV1CoreAuthEmailVerifyMutation,
} from "store/api/auth";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_HOME, LOGIN } from "constants/routing";
import { hasError, ResponseQuery } from "utils/helpers";
import OwnerStep from "components/authentification/OwnerStep";
import OrganizationStep from "components/authentification/OrganisationStep";
import OtpStep from "components/authentification/OtpStep";

export interface RegisterFormInterface {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  password: string;
  name: string;
  organisation_email: string;
  organisation_phone: string;
  city: string;
  country: string;
  otp: string;
}

const StepComponent = ({
  currentStep,
  errorMessage,
  isLoading,
  onSubmitOtp,
}: {
  currentStep: RegisterStepEnum;
  errorMessage: string;
  isLoading: boolean;
  onSubmitOtp: () => void;
}) => {
  switch (currentStep) {
    case RegisterStepEnum.ownerStep:
      return OwnerStep({ errorMessage, isLoading });
    case RegisterStepEnum.organisationStep:
      return OrganizationStep({ errorMessage, isLoading });
    case RegisterStepEnum.otpStep:
      return OtpStep({
        errorMessage,
        isLoading,
        onSubmitOtp,
      });
    default:
      return OwnerStep({ errorMessage, isLoading });
  }
};

const Register = () => {
  const [register, { isLoading, data }] =
    usePostApiV1CoreByAccountTypeRegisterMutation();
  const [verifyOtp, { isLoading: isLoadingVerifyOtp }] =
    usePostApiV1CoreAuthEmailVerifyMutation();

  const [resendOtp, { isLoading: isLoadingResendOtp }] =
    usePostApiV1CoreAuthEmailResendOtpMutation();
  const [step, setStep] = useState<RegisterStepEnum>(
    RegisterStepEnum.ownerStep
  );
  const [errorMessage, setErrorMessage] = useState("");
  const validationStep = {
    [RegisterStepEnum.ownerStep]: RegisterOwnerStepValidation,
    [RegisterStepEnum.otpStep]: RegisterOwnerStepValidation,
    [RegisterStepEnum.organisationStep]: RegisterOwnerStepValidation,
  };
  const onNext = () => {
    setStep(RegisterStepEnum.otpStep);
  };

  const computeRegisterValue = (values: RegisterFormInterface) => {
    const result = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone: values.phone,
      organization: {
        name: values.name,
        phone: values.organisation_phone,
        city: "Cotonou",
        country: "Benin",
        email: values.organisation_email,
      },
      email: values.email,
      password: values.password,
    };
    return result;
  };

  const navigate = useNavigate();
  const handleRegister = async (values: RegisterFormInterface) => {
    try {
      const response = await register({
        accountType: "owner",
        register: computeRegisterValue(values),
      }).unwrap();
      if (response) {
        onNext();
      }
    } catch (err) {
      hasError({
        response: err as ResponseQuery,
        onError: setErrorMessage,
      });
    }
  };

  const handleSubmit = async (values: RegisterFormInterface) => {
    if (step == RegisterStepEnum.ownerStep) {
      setStep(RegisterStepEnum.organisationStep);
    } else if (step == RegisterStepEnum.organisationStep) {
      handleRegister(values);
    } else if (step == RegisterStepEnum.otpStep) {
      try {
        const response = await verifyOtp({
          emailOtpCheck: {
            email: values.email,
            code: values.otp,
          },
        }).unwrap();
        if (response) {
          navigate(DASHBOARD_HOME);
        }
      } catch (err) {
        hasError({
          response: err as ResponseQuery,
          onError: setErrorMessage,
        });
      }
    }
  };

  const handleResendOtp = async () => {
    if (data) {
      try {
        await resendOtp({ resendOtp: { email: data?.email } });
      } catch (err) {
        hasError({
          response: err as ResponseQuery,
          onError: setErrorMessage,
        });
      }
    }
  };

  useEffect(() => {
    if (step === 2) {
      if (errorMessage) {
        setErrorMessage("");
      }
    }
  }, [step, errorMessage]);

  return (
    <Flex pt="100px" w="full" justifyContent="center">
      <Box
        borderRadius="7px"
        bg="white"
        padding="50px"
        boxShadow="xl"
        w={{ base: "400px", md: "500px" }}
      >
        <Flex mb="30px" justifyContent="space-between">
          <Box>
            <Text color="darkPrimary" variant="textBodyBold">
              Welcome to Transfa
            </Text>
          </Box>
          <Box>
            <Text color="gray">Already have account ?</Text>
            <Link color="primary" href={LOGIN}>
              Sign In
            </Link>
          </Box>
        </Flex>
        <Formik<RegisterFormInterface>
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            city: "",
            name: "",
            organisation_email: "",
            organisation_phone: "",
            country: "",
            phone: "",
            otp: "",
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          validationSchema={validationStep[step]}
        >
          <Form>
            <StepComponent
              errorMessage={errorMessage}
              currentStep={step}
              isLoading={
                isLoading ||
                isLoadingResendOtp ||
                isLoadingResendOtp ||
                isLoadingVerifyOtp
              }
              onSubmitOtp={handleResendOtp}
            />
          </Form>
        </Formik>
        <Flex justifyContent="center" my="10px" w="full">
          <Text textAlign="end">
            Have already account ?{" "}
            <Link color="primary" href={LOGIN}>
              Login here.
            </Link>
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Register;
