import { Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Box, Link, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "store/index";

import { useEffect, useState } from "react";
import { ELEMENT_PER_PAGE } from "constants/index";
import { formatDate, listSelectOptions } from "utils/helpers";
import SearchFilter from "components/Filters/SearchFilter";
import SelectFilter from "components/Filters/SelectFilter";
import {
  PayoutStatusEnum,
  useListApiV1CoreOrganizationPayoutQuery,
} from "store/api/organization";

const payoutStatus: PayoutStatusEnum[] = ["pending", "success"];

const columns: ColumnsType<any> = [
  {
    title: "Reference",
    dataIndex: "reference",
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value) => {
      if (value == "failed") {
        return <Text color="stateError">{value}</Text>;
      }
      return <Text color="stateSuccess">{value}</Text>;
    },
  },
  {
    title: "Transaction Link",
    dataIndex: "id",
    render: (value) => {
      return (
        <Link color="primary" textDecoration="underline">
          {value}
        </Link>
      );
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: true,
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Payout Date",
    dataIndex: "created",
    render: (value) => <Text>{formatDate(value)}</Text>,
  },
];

const Payouts = () => {
  const organization = useSelector(
    (state: RootState) => state.organization.currentOrganization
  );
  const organizationId = !!organization ? organization.id : "";
  const [queryParams, setQueryParams] = useState<any>({});

  const [page, setPage] = useState(1);
  const { data: payouts, isFetching: isFetchingPayouts } =
    useListApiV1CoreOrganizationPayoutQuery({
      organizationPk: organizationId,
      page: page,
      ...queryParams,
    });

  const [statusFilterValue, setStatusFilterValue] = useState<string | null>(
    null
  );

  const handleAddQueryParam = (param: any, value: any) => {
    const queryParam: any = {};
    queryParam[param] = value;
    setQueryParams({ ...queryParams, ...queryParam });
  };

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);

    window.scrollTo({ top: 70, behavior: "smooth" });
  };

  useEffect(() => {
    if (statusFilterValue) {
      handleAddQueryParam("status", statusFilterValue);
    } else {
      setQueryParams({});
    }
  }, [statusFilterValue]);

  return (
    <Box w="full">
      <Box marginY="24px">
        <Text variant="subTitleBold">Payouts</Text>
      </Box>
      <Space
        style={{
          marginBottom: 15,
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
        }}
      >
        <SearchFilter handleSearch={handleAddQueryParam} />
        <Space>
          <SelectFilter
            placeholder="Filter by status"
            options={listSelectOptions(payoutStatus)}
            value={statusFilterValue}
            handleFiltering={setStatusFilterValue}
          />
        </Space>
      </Space>
      <Table
        columns={columns}
        dataSource={payouts?.results}
        loading={isFetchingPayouts}
        pagination={{
          onChange: handlePageChange,
          current: page,
          total: payouts?.count,
          pageSize: ELEMENT_PER_PAGE,
          showSizeChanger: false,
        }}
      />
    </Box>
  );
};

export default Payouts;
