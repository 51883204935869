import { Styles } from "@chakra-ui/theme-tools";

const styles: Styles = {
  global: {
    body: {
      fontFamily: "body",
      color: "darkPurple",
      bg: "white",
      h: "100vh",
      w: "100vw",
    },
  },
};

export default styles;
