import InputField from "uikit/inputs/InputField";
import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
  Link as ChackraLink,
} from "@chakra-ui/react";
import { FORGOT_PASSWORD } from "constants/routing";

interface OrganizationStepInterface {
  errorMessage: string;
  isLoading: boolean;
}

const OrganizationStep = ({
  errorMessage,
  isLoading,
}: OrganizationStepInterface) => {
  return (
    <Box>
      <Flex mb="20px">
        <Text color="darkPrimary" variant="title1">
          Organization
        </Text>
      </Flex>
      <VStack>
        <HStack>
          <InputField label="Organization name" name="name" type="text" />
          <Spacer />
          <InputField
            label="Organization phone"
            name="organisation_phone"
            type="text"
          />
        </HStack>
        <InputField label="Organization e-mail" name="organisation_email" />
      </VStack>
      {errorMessage && (
        <Text my="3px" color="stateError">
          {errorMessage}
        </Text>
      )}
      <Button isLoading={isLoading} my="15px" w="full" type="submit">
        Sign in
      </Button>

      <Flex justifyContent="end" my="10px" w="full">
        <Box>
          <Text textAlign="end">
            <ChackraLink color="primary" href={FORGOT_PASSWORD}>
              Forgot password
            </ChackraLink>
          </Text>
        </Box>
      </Flex>
      <Flex justifyContent="center" my="5px" w="full">
        <Box>
          <Text textAlign="center">
            By continuing, you agree to Transfa{" "}
            <ChackraLink color="primary" href="#" isExternal>
              Terms of Use
            </ChackraLink>
            . Read our{" "}
            <ChackraLink color="primary" href="#" isExternal>
              Privacy Policy
            </ChackraLink>
            .
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default OrganizationStep;
