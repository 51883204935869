import { api } from "./index";
import { Currency } from "./currency";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiV1CoreOrganizationByOrganizationPkWalletTransaction: build.query<
      GetApiV1CoreOrganizationByOrganizationPkWalletTransactionApiResponse,
      GetApiV1CoreOrganizationByOrganizationPkWalletTransactionApiArg
    >({
      query: (queryArg) => {
        return {
          url: `/api/v1/core/organization/${queryArg.organizationPk}/wallet_transaction/`,
          params: {
            ordering: queryArg.ordering,
            page: queryArg.page,
            source: queryArg.source,
            reference: queryArg.reference,
            type: queryArg.type,
            search: queryArg.search,
          },
        };
      },
    }),
    getApiV1CoreOrganizationByOrganizationPkWalletTransactionAndId: build.query<
      GetApiV1CoreOrganizationByOrganizationPkWalletTransactionAndIdApiResponse,
      GetApiV1CoreOrganizationByOrganizationPkWalletTransactionAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.organizationPk}/wallet_transaction/${queryArg.id}/`,
      }),
    }),
    getApiV1CoreWallet: build.query<
      GetApiV1CoreWalletApiResponse,
      GetApiV1CoreWalletApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/wallet/`,
        params: { ordering: queryArg.ordering, page: queryArg.page },
      }),
    }),
    getApiV1CoreWalletById: build.query<
      GetApiV1CoreWalletByIdApiResponse,
      GetApiV1CoreWalletByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/core/wallet/${queryArg.id}/` }),
    }),
  }),
  overrideExisting: true,
});
export { injectedRtkApi as wallet };
export type GetApiV1CoreOrganizationByOrganizationPkWalletTransactionApiResponse =
  /** status 200  */ PaginatedWalletTransactionList;
export type GetApiV1CoreOrganizationByOrganizationPkWalletTransactionApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  organizationPk: string;
  /** A page number within the paginated result set. */
  page?: number;
  search?: string;
  reference?: string;
  type?: string;
  source?: string;
};
export type GetApiV1CoreOrganizationByOrganizationPkWalletTransactionAndIdApiResponse =
  /** status 200  */ WalletTransaction;
export type GetApiV1CoreOrganizationByOrganizationPkWalletTransactionAndIdApiArg =
  {
    id: string;
    organizationPk: string;
  };
export type GetApiV1CoreWalletApiResponse =
  /** status 200  */ PaginatedWalletList;
export type GetApiV1CoreWalletApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type GetApiV1CoreWalletByIdApiResponse = /** status 200  */ Wallet;
export type GetApiV1CoreWalletByIdApiArg = {
  /** A unique integer value identifying this wallet. */
  id: number;
};
export type WalletTransactionTypeEnum = "debit" | "credit" | "fund_card";
export type WalletTransaction = {
  id: string;
  wallet: string;
  amount: string;
  amount_str: string;
  type: WalletTransactionTypeEnum;
  note?: string | null;
  reference?: string;
  created: string;
  updated: string;
  payment: string;
};
export type PaginatedWalletTransactionList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: WalletTransaction[];
};
export type Wallet = {
  id: string;
  currency: Currency;
  balance: number;
  pending_balance: string;
  due: string;
  pending_balance_str: string;
  balance_str: string;
  payments_count: string;
  due_str: string;
  created: string;
  updated: string;
};
export type PaginatedWalletList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Wallet[];
};
export const {
  useGetApiV1CoreOrganizationByOrganizationPkWalletTransactionQuery,
  useGetApiV1CoreOrganizationByOrganizationPkWalletTransactionAndIdQuery,
  useGetApiV1CoreWalletQuery,
  useGetApiV1CoreWalletByIdQuery,
} = injectedRtkApi;
