import { Login } from "./pages";
import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";
import {
  DASHBOARD_DEVELOPERS,
  DASHBOARD_SETTINGS,
  DASHBOARD_PAYMENTS,
  LOGIN,
  DASHBOARD_HOME,
  DASHBOARD_CONTACTS_US,
  REGISTER,
  FORGOT_PASSWORD,
  DASHBOARD_PAYOUTS,
} from "constants/routing";
import Layout from "components/Layout";
import Home from "pages/Home";
import Payments from "pages/Payments";
import Developers from "pages/Developers";
import Settings from "pages/Settings";
import ContactUs from "pages/ContactUs";
import ProtectedRoute from "routes/ProtectedRoute";
import PageNotFound from "pages/PageNoteFound";
import "antd/dist/antd.css";
import Register from "pages/Register";
import ForgotPassword from "pages/ForgotPassword";
import BackgroundLayout from "components/BackgroundLayout";
import Payouts from "pages/Payouts";

export default function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="*" element={<PageNotFound />} />
          <Route
            path={LOGIN}
            element={
              <BackgroundLayout>
                <Login />
              </BackgroundLayout>
            }
          />
          <Route
            path={REGISTER}
            element={
              <BackgroundLayout>
                <Register />
              </BackgroundLayout>
            }
          />
          <Route
            path={FORGOT_PASSWORD}
            element={
              <BackgroundLayout>
                <ForgotPassword />
              </BackgroundLayout>
            }
          />
          <Route
            path={DASHBOARD_HOME}
            element={
              <ProtectedRoute redirectPath={LOGIN}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path={DASHBOARD_PAYMENTS}
            element={
              <ProtectedRoute redirectPath={LOGIN}>
                <Payments />
              </ProtectedRoute>
            }
          />
          <Route
            path={DASHBOARD_PAYOUTS}
            element={
              <ProtectedRoute redirectPath={LOGIN}>
                <Payouts />
              </ProtectedRoute>
            }
          />
          <Route
            path={DASHBOARD_DEVELOPERS}
            element={
              <ProtectedRoute redirectPath={LOGIN}>
                <Developers />
              </ProtectedRoute>
            }
          />
          <Route
            path={DASHBOARD_SETTINGS}
            element={
              <ProtectedRoute redirectPath={LOGIN}>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path={DASHBOARD_CONTACTS_US}
            element={
              <ProtectedRoute redirectPath={LOGIN}>
                <ContactUs />
              </ProtectedRoute>
            }
          />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}
