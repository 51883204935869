export const LOGIN = "/";
export const FORGOT_PASSWORD = "/forgot-password";
export const PAGE_NOT_FOUND = "/404";
export const REGISTER = "/register";
export const DASHBOARD_HOME = "/app/home";
export const DASHBOARD_PAYMENTS = "/app/payments";
export const DASHBOARD_DEVELOPERS = "/app/developers";
export const DASHBOARD_SETTINGS = "/app/settings";
export const DASHBOARD_CONTACTS_US = "/app/contact";
export const DASHBOARD_PAYOUTS = "/app/payouts";

export const ROUTE_WITH_NO_SIDEBAR = [
  LOGIN,
  REGISTER,
  PAGE_NOT_FOUND,
  FORGOT_PASSWORD,
];
export const ROUTES_ALL = [
  LOGIN,
  DASHBOARD_HOME,
  DASHBOARD_PAYMENTS,
  DASHBOARD_DEVELOPERS,
  DASHBOARD_SETTINGS,
  DASHBOARD_CONTACTS_US,
  DASHBOARD_PAYOUTS,
  REGISTER,
  PAGE_NOT_FOUND,
  FORGOT_PASSWORD,
];

export type RoutingType =
  | typeof LOGIN
  | typeof DASHBOARD_HOME
  | typeof DASHBOARD_PAYMENTS
  | typeof DASHBOARD_DEVELOPERS
  | typeof DASHBOARD_SETTINGS
  | typeof DASHBOARD_CONTACTS_US
  | typeof DASHBOARD_PAYOUTS
  | typeof REGISTER
  | typeof FORGOT_PASSWORD
  | typeof PAGE_NOT_FOUND;
