import { Box, Button, Flex, Text } from "@chakra-ui/react";
import OtpInput from "uikit/inputs/OtpInputField";

interface OrganizationStepInterface {
  errorMessage: string;
  isLoading: boolean;
  onSubmitOtp: () => void;
}

const OtpStep = ({
  errorMessage,
  isLoading,
  onSubmitOtp,
}: OrganizationStepInterface) => {
  return (
    <Box>
      <Flex mb="20px">
        <Text color="darkPrimary" variant="title1">
          Otp verify
        </Text>
      </Flex>
      <Text color="darkPrimary">
        Votre code de confirmation a été envoyé à votre mail
      </Text>
      <Flex justifyContent="center">
        <OtpInput required={true} name={"otp"} />
      </Flex>
      {errorMessage && (
        <Text my="3px" color="stateError">
          {errorMessage}
        </Text>
      )}
      <Button isLoading={isLoading} my="15px" w="full" type="submit">
        Verify
      </Button>
      <Flex justifyContent="end" w="full">
        <Box cursor="pointer" onClick={() => onSubmitOtp()}>
          <Text color="primary">Resend otp</Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default OtpStep;
