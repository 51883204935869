const outlineButtonVariants = {
  outline: {
    bg: "white",
    color: "primary",
    borderWidth: "2px",
    borderColor: "primary",
    _hover: {
      bg: "white",
      _disabled: {
        bg: "white",
      },
    },
  },

  outlineGray: {
    bg: "white",
    color: "gray",
    borderWidth: "2px",
    borderColor: "gray",
    _hover: {
      bg: "white",
      _disabled: {
        bg: "white",
      },
    },
  },
};

export default outlineButtonVariants;
