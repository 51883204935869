import { extendTheme } from "@chakra-ui/react";
//todo import font

// Global style overrides
import styles from "theme/styles";
// Component style overrides
import Button from "theme/uiKit/inputs/button";
import typography from "theme/foundations/typography";
import Text from "theme/uiKit/display/text";
import colors from "theme/foundations/colors";

const overrides = {
  styles,
  typography,
  colors,
  //borders,
  // Other foundational style overrides go here
  components: {
    Button,
    Text,
    // Other components go here
  },
};
const Theme = extendTheme(overrides);

export default Theme;
