import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Text: ComponentStyleConfig = {
  baseStyle: {
    lineHeight: "28px",
    fontWeight: "400",
  },
  variants: {
    textHead: {
      fontSize: "13px",
      fontWeight: "500",
    },
    textMedium: {
      fontSize: "15px",
      fontWeight: "500",
    },
    textBody: {
      fontSize: "17px",
    },
    textBodyBold: {
      fontSize: "20px",
      fontWeight: "700",
    },
    textMicro: {
      lineHeight: "21px",
      fontSize: "12px",
    },
    textMicroBold: {
      fontsize: "14px",
      fontWeight: "700",
      lineHeight: "21px",
    },
    textSmall: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    textSmallBold: {
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "24px",
    },
    title1: {
      fontSize: "48px",
      fontWeight: "bold",
      lineHeight: "59px",
    },
    title2: {
      fontSize: "40px",
      fontWeight: "bold",
      lineHeight: "24px",
    },
    title3: {
      fontSize: "30px",
      fontWeight: "semi-bold",
      lineHeight: "24px",
    },
    heading: {
      fontSize: "16px",
      fontWeight: "medium",
      lineHeight: "32px",
    },
    heading2: {
      fontSize: "14px",
      fontWeight: "regular",
    },
    title: {
      fontSize: "34px",
      fontWeight: "600",
    },
    subTitle: {
      fontSize: "24px",
      fontWeight: "600",
    },
    subTitleBold: {
      fontSize: "24px",
      fontWeight: "700",
    },
  },
  sizes: {},
  defaultProps: {
    variant: "",
    size: "",
  },
};

export default Text;
