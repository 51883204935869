import { Button, Flex, Text, Box } from "@chakra-ui/react";
import { REGISTER } from "constants/routing";
import { Link } from "react-router-dom";
import InputField from "uikit/inputs/InputField";
import OtpInput from "uikit/inputs/OtpInputField";

interface ForgotStepInterface {
  isLoading: boolean;
  errorMessage?: string;
  onSubmit?: () => void;
}
export const MailStep = ({ isLoading, errorMessage }: ForgotStepInterface) => (
  <Box>
    <Flex mb="30px">
      <Text color="darkPrimary" variant="title1">
        Reset password
      </Text>
    </Flex>
    <Box>
      <InputField label="Email" name="email" type="text" />
    </Box>
    {errorMessage && (
      <Text my="3px" color="stateError">
        {errorMessage}
      </Text>
    )}
    <Button isLoading={isLoading} my="15px" w="full" type="submit">
      Send otp
    </Button>

    <Flex justifyContent="center" my="10px" w="full">
      <Text textAlign="end">
        Don't have an account?{" "}
        <Link to={REGISTER}>
          <Text textDecoration="underline" color="primary" display="inline">
            Register here
          </Text>
          .
        </Link>
      </Text>
    </Flex>
  </Box>
);

export const OtpStep = ({ isLoading, errorMessage }: ForgotStepInterface) => {
  return (
    <Box>
      <Flex mb="20px">
        <Text color="darkPrimary" variant="title1">
          Otp verify
        </Text>
      </Flex>
      <Text color="darkPrimary">
        Votre code de confirmation a été envoyé à votre mail
      </Text>
      <Flex justifyContent="center">
        <Box>
          <OtpInput required={true} name={"code"} />
        </Box>
      </Flex>
      {errorMessage && (
        <Text my="3px" color="stateError">
          {errorMessage}
        </Text>
      )}
      <Button type="submit" isLoading={isLoading} my="15px" w="full">
        Verify
      </Button>
    </Box>
  );
};

export const UpdatePasswordStep = ({
  isLoading,
  errorMessage,
}: ForgotStepInterface) => (
  <Box>
    <Flex mb="30px">
      <Text color="darkPrimary" variant="title1">
        Reset password
      </Text>
    </Flex>
    <Box>
      <InputField
        hasShowPasswordIcon={false}
        label="New password"
        name="new_password"
        type="password"
      />
    </Box>
    <Box>
      <InputField
        label="Confirm your new password"
        name="confirm_new_password"
        type="password"
      />
    </Box>

    {errorMessage && (
      <Text my="3px" color="stateError">
        {errorMessage}
      </Text>
    )}
    <Button isLoading={isLoading} my="15px" w="full" type="submit">
      Reset password
    </Button>

    <Flex justifyContent="center" my="10px" w="full">
      <Text textAlign="end">
        Don't have an account?{" "}
        <Link to={REGISTER}>
          Register{" "}
          <Text textDecoration="underline" color="primary" display="inline">
            here
          </Text>
          .
        </Link>
      </Text>
    </Flex>
  </Box>
);
