import { Form, Formik } from "formik";
import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ForgotPasswordFormType,
  forgotPasswordinitialValues,
  ForgotPasswordStepEnum,
  forgotPasswordValidation,
  MailStepType,
  OtpStepType,
  UpdatePasswordStepType,
} from "configs/forgotPassword";
import {
  MailStep,
  OtpStep,
  UpdatePasswordStep,
} from "components/authentication/forgotPasswordSteps";
import {
  usePostApiV1CoreAuthResetPasswordMutation,
  usePostApiV1CoreAuthResetPasswordOtpVerificationMutation,
  usePostApiV1CoreAuthResetPasswordResetMutation,
} from "store/api/auth";
import { useDispatch } from "react-redux";
import { notify } from "store/slices/NotifyToastSlice";
import { toast } from "react-toastify";
import { hasError, ResponseQuery } from "utils/helpers";
import { Link, useNavigate } from "react-router-dom";
import { LOGIN, REGISTER } from "constants/routing";
import HeaderAuth from "components/authentication/HeaderAuth";

const ForgotPassword = () => {
  const [step, setStep] = useState<ForgotPasswordStepEnum>(
    ForgotPasswordStepEnum.mailStep
  );
  const [resetPassword, { isLoading: isLoadingSendOtp }] =
    usePostApiV1CoreAuthResetPasswordMutation();
  const [verifyOtp, { isLoading: isLoadingVerifyOtp }] =
    usePostApiV1CoreAuthResetPasswordOtpVerificationMutation();
  const [updatePassword, { isLoading: isLoadingUpdatePassword }] =
    usePostApiV1CoreAuthResetPasswordResetMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const [sendRequestMail, setRequestedMail] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const disptach = useDispatch();

  const stepComponent = (currentStep: ForgotPasswordStepEnum) => {
    switch (currentStep) {
      case ForgotPasswordStepEnum.mailStep:
        return MailStep({ isLoading: isLoadingSendOtp, errorMessage });
      case ForgotPasswordStepEnum.otpStep:
        return OtpStep({ isLoading: isLoadingVerifyOtp, errorMessage });
      case ForgotPasswordStepEnum.passwordUpdateStep:
        return UpdatePasswordStep({
          isLoading: isLoadingUpdatePassword,
          errorMessage,
        });
      default:
        return MailStep({ isLoading: isLoadingSendOtp, errorMessage });
    }
  };
  const handleSendOtp = async ({ email }: MailStepType) => {
    try {
      const { email: responseMail } = await resetPassword({
        passwordResetEmail: { email },
      }).unwrap();
      if (responseMail) {
        disptach(
          notify({
            message: `Otp has sent to your mail ${responseMail}`,
            typeToast: toast.TYPE.SUCCESS,
          })
        );
        setStep(ForgotPasswordStepEnum.otpStep);
        setRequestedMail(responseMail);
      }
    } catch (err) {
      hasError({
        response: err as ResponseQuery,
        displayToast: false,
        onError: () => {},
      });
    }
  };

  const handleVerifyOtp = async ({ code }: OtpStepType) => {
    try {
      const { token } = await verifyOtp({
        emailOtpCheck: { email: sendRequestMail, code },
      }).unwrap();
      if (token) {
        setStep(ForgotPasswordStepEnum.passwordUpdateStep);
        setToken(token);
      }
    } catch (err) {
      hasError({
        response: err as ResponseQuery,
        displayToast: false,
        onError: (details) => setErrorMessage(details),
      });
    }
  };

  const handlePasswordUpdate = async (
    value: UpdatePasswordStepType,
    setMessageError: (key: string, value?: string) => void
  ) => {
    try {
      const data = await updatePassword({
        passwordResetEmail: value,
        token,
      }).unwrap();
      if (data) {
        disptach(
          notify({
            message: `Your password has been updated`,
            typeToast: toast.TYPE.SUCCESS,
          })
        );
        navigate(LOGIN);
      }
    } catch (err) {
      hasError({
        response: err as ResponseQuery,
        displayToast: true,
        onError: (key, details) => setMessageError(key, details),
      });
    }
  };

  const onSubmit = (
    currentStep: ForgotPasswordStepEnum,
    value: ForgotPasswordFormType,
    setMessageError: (key: string, value?: string) => void
  ) => {
    switch (currentStep) {
      case ForgotPasswordStepEnum.mailStep:
        handleSendOtp(value as MailStepType);
        break;
      case ForgotPasswordStepEnum.otpStep:
        handleVerifyOtp(value as OtpStepType);
        break;
      case ForgotPasswordStepEnum.passwordUpdateStep:
        handlePasswordUpdate(value as UpdatePasswordStepType, setMessageError);
        break;
      default:
        break;
    }
  };
  useEffect(() => setErrorMessage(""), [step]);

  return (
    <Flex
      data-testid="Login"
      w="full"
      h="full"
      alignItems="center"
      justifyContent="center"
      p="20px"
    >
      <Formik
        onSubmit={(values, helpers) =>
          onSubmit(
            step,
            values as ForgotPasswordFormType,
            helpers.setFieldError
          )
        }
        initialValues={forgotPasswordinitialValues[step]}
        validationSchema={forgotPasswordValidation[step]}
        enableReinitialize
      >
        <Form>
          <Box
            borderRadius="7px"
            padding="50px"
            boxShadow="xl"
            w={{ base: "400px", md: "500px" }}
            bg="white"
          >
            <HeaderAuth
              title="Welcome to Transfa"
              subTitle="No account ?"
              children={<Link to={REGISTER}>Sign Up</Link>}
            />
            {stepComponent(step)}
          </Box>
        </Form>
      </Formik>
    </Flex>
  );
};

export default ForgotPassword;
