import { Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Box, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "store/index";
import {
  FinancialStatusEnum,
  ModeEnum,
  PaymentStatusEnum,
  PaymentTypeEnum,
  useGetApiV1CoreOrganizationByOrganizationPkPaymentQuery,
} from "store/api/payment";
import { useEffect, useState } from "react";
import { ELEMENT_PER_PAGE } from "constants/index";
import { formatDate, listSelectOptions } from "utils/helpers";
import SearchFilter from "components/Filters/SearchFilter";
import SelectFilter from "components/Filters/SelectFilter";

const paymentStatus: PaymentStatusEnum[] = [
  "completed",
  "processing",
  "pending",
  "failed",
];

const financialStatus: FinancialStatusEnum[] = [
  "success",
  "failed",
  "refunded",
  "pending",
  "cancelled",
];

const paymentTypes: PaymentTypeEnum[] = ["request-payment", "transfer"];

const paymentMode: ModeEnum[] = ["mtn-benin", "moov-benin"];

const columns: ColumnsType<any> = [
  {
    title: "Reference",
    dataIndex: "reference",
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Account number",
    dataIndex: "account_alias",
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (value) => {
      if (value == "failed") {
        return <Text color="stateError">{value}</Text>;
      }
      return <Text color="stateSuccess">{value}</Text>;
    },
  },
  {
    title: "Financial Status",
    dataIndex: "financial_status",
    render: (value) => {
      if (value == "failed") {
        return <Text color="stateError">{value}</Text>;
      }
      return <Text color="stateSuccess">{value}</Text>;
    },
  },
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: true,
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Type",
    dataIndex: "type",
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Mode",
    dataIndex: "mode",
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Transaction Date",
    dataIndex: "created",
    render: (value) => <Text>{formatDate(value)}</Text>,
  },
];

const Payments = () => {
  const organization = useSelector(
    (state: RootState) => state.organization.currentOrganization
  );
  const organizationId = !!organization ? organization.id : "";
  const [queryParams, setQueryParams] = useState<any>({});

  const [page, setPage] = useState(1);
  const { data: payments, isFetching: isFetchingPayments } =
    useGetApiV1CoreOrganizationByOrganizationPkPaymentQuery({
      organizationPk: organizationId,
      page: page,
      ...queryParams,
    });

  const [statusFilterValue, setStatusFilterValue] = useState<string | null>(
    null
  );
  const [financialStatusFilterValue, setFinancialStatusFilterValue] = useState<
    string | null
  >(null);
  const [modeFilterValue, setModeFilterValue] = useState<string | null>(null);
  const [typeFilterValue, setTypeFilterValue] = useState<string | null>(null);

  const handleAddQueryParam = (param: any, value: any) => {
    const queryParam: any = {};
    queryParam[param] = value;
    setQueryParams({ ...queryParams, ...queryParam });
  };

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);

    window.scrollTo({ top: 70, behavior: "smooth" });
  };

  useEffect(() => {
    if (statusFilterValue) {
      handleAddQueryParam("status", statusFilterValue);
    } else {
      setQueryParams({});
    }
  }, [statusFilterValue]);

  useEffect(() => {
    if (typeFilterValue) {
      handleAddQueryParam("type", typeFilterValue);
    } else {
      setQueryParams({});
    }
  }, [typeFilterValue]);

  useEffect(() => {
    if (modeFilterValue) {
      handleAddQueryParam("mode", modeFilterValue);
    } else {
      setQueryParams({});
    }
  }, [modeFilterValue]);

  useEffect(() => {
    if (financialStatusFilterValue) {
      handleAddQueryParam("financial_status", financialStatusFilterValue);
    } else {
      setQueryParams({});
    }
  }, [financialStatusFilterValue]);

  return (
    <Box w="full">
      <Box marginY="24px">
        <Text variant="subTitleBold">Payments</Text>
      </Box>
      <Space
        style={{
          marginBottom: 15,
          marginTop: 15,
          display: "flex",
          flexDirection: "column",
          alignItems: "baseline",
        }}
      >
        <SearchFilter handleSearch={handleAddQueryParam} />
        <Space>
          <SelectFilter
            placeholder="Filter by status"
            options={listSelectOptions(paymentStatus)}
            value={statusFilterValue}
            handleFiltering={setStatusFilterValue}
          />

          <SelectFilter
            placeholder="Filter by financial status"
            options={listSelectOptions(financialStatus)}
            value={financialStatusFilterValue}
            handleFiltering={setFinancialStatusFilterValue}
          />
          <SelectFilter
            placeholder="Filter by type"
            options={listSelectOptions(paymentTypes)}
            value={typeFilterValue}
            handleFiltering={setTypeFilterValue}
          />
          <SelectFilter
            placeholder="Filter by mode"
            options={listSelectOptions(paymentMode)}
            value={modeFilterValue}
            handleFiltering={setModeFilterValue}
          />
        </Space>
      </Space>
      <Table
        columns={columns}
        dataSource={payments?.results}
        loading={isFetchingPayments}
        pagination={{
          onChange: handlePageChange,
          current: page,
          total: payments?.count,
          pageSize: ELEMENT_PER_PAGE,
          showSizeChanger: false,
        }}
      />
    </Box>
  );
};

export default Payments;
