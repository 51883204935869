import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "store/api/account";
import { TokenRefresh } from "store/api/auth";

export type State = TokenRefresh & {
  user: User | null;
};

export const initialState: State = { access: "", refresh: "", user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(state: State, action: PayloadAction<TokenRefresh>) {
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
    },
    setAccount(state: State, action: PayloadAction<{ user: User }>) {
      state.user = action.payload.user;
    },
    setLogout(state: State) {
      state.user = null;
      state.refresh = "";
      state.access = "";
    },
  },
});
export default authSlice;
