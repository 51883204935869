import { api } from "./index";
import organizationSlice from "store/slices/organisation";
import { Owner } from "./account";
import authSlice from "store/slices/auth";
import { notify } from "store/slices/NotifyToastSlice";
import { toast } from "react-toastify";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postApiV1CoreByAccountTypeAuth: build.mutation<
      PostApiV1CoreByAccountTypeAuthApiResponse,
      PostApiV1CoreByAccountTypeAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/auth/`,
        method: "POST",
        body: queryArg.login,
      }),
    }),

    postApiV1CoreAuthEmailResendOtp: build.mutation<
      PostApiV1CoreAuthEmailResendOtpApiResponse,
      PostApiV1CoreAuthEmailResendOtpApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/auth/email/resend_otp/`,
        method: "POST",
        body: queryArg.resendOtp,
      }),
    }),
    postApiV1CoreAuthEmailVerify: build.mutation<
      PostApiV1CoreAuthEmailVerifyApiResponse,
      PostApiV1CoreAuthEmailVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/auth/email/verify/`,
        method: "POST",
        body: queryArg.emailOtpCheck,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organizationSlice.actions.setCurrentOrganization(
              data.user.organization_root
            )
          );
          dispatch(authSlice.actions.setAccount({ user: data.user }));
          dispatch(
            authSlice.actions.setAuthTokens({
              access: data.access,
              refresh: data.refresh,
            })
          );
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    postApiV1CoreAuthLogout: build.mutation<
      PostApiV1CoreAuthLogoutApiResponse,
      PostApiV1CoreAuthLogoutApiArg
    >({
      query: () => ({ url: `/api/v1/core/auth/logout/`, method: "POST" }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(authSlice.actions.setLogout());
          }
        } catch (err) {
          // `onError` side-effect
          dispatch(
            notify({
              message: "Logout failed",
              typeToast: toast.TYPE.ERROR,
            })
          );
        }
      },
    }),
    postApiV1CoreAuthRefresh: build.mutation<
      PostApiV1CoreAuthRefreshApiResponse,
      PostApiV1CoreAuthRefreshApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/auth/refresh/`,
        method: "POST",
        body: queryArg.tokenRefresh,
      }),
    }),
    postApiV1CoreAuthResetPassword: build.mutation<
      PostApiV1CoreAuthResetPasswordApiResponse,
      PostApiV1CoreAuthResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/auth/reset_password/`,
        method: "POST",
        body: queryArg.passwordResetEmail,
      }),
    }),
    postApiV1CoreAuthResetPasswordOtpVerification: build.mutation<
      PostApiV1CoreAuthResetPasswordOtpVerificationApiResponse,
      PostApiV1CoreAuthResetPasswordOtpVerificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/auth/reset_password/otp_verification/`,
        method: "POST",
        body: queryArg.emailOtpCheck,
      }),
    }),
    postApiV1CoreAuthResetPasswordReset: build.mutation<
      PostApiV1CoreAuthResetPasswordResetApiResponse,
      PostApiV1CoreAuthResetPasswordResetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/auth/reset_password/reset/`,
        method: "POST",
        body: queryArg.passwordResetEmail,
        headers: {
          Authorization: `Token ${queryArg.token}`,
        },
      }),
    }),
  }),
  overrideExisting: true,
});
export { injectedRtkApi as auth };
export type PostApiV1CoreByAccountTypeAuthApiResponse =
  /** status 201  */ Login;
export type PostApiV1CoreByAccountTypeAuthApiArg = {
  accountType: string;
  login: Login;
};

export type PostApiV1CoreAuthEmailResendOtpApiResponse =
  /** status 200  */ ResendOtp;
export type PostApiV1CoreAuthEmailResendOtpApiArg = {
  resendOtp: ResendOtp;
};
export type PostApiV1CoreAuthEmailVerifyApiResponse = TokenRefresh & {
  user: Owner;
};
export type PostApiV1CoreAuthEmailVerifyApiArg = {
  emailOtpCheck: EmailOtpCheck;
};
export type PostApiV1CoreAuthLogoutApiResponse =
  /** status 200  */ DetailResponse;
export type PostApiV1CoreAuthLogoutApiArg = void;
export type PostApiV1CoreAuthRefreshApiResponse =
  /** status 201  */ TokenRefresh;
export type PostApiV1CoreAuthRefreshApiArg = {
  tokenRefresh: TokenRefresh;
};
export type PostApiV1CoreAuthResetPasswordApiResponse =
  /** status 201  */ PasswordResetEmail;
export type PostApiV1CoreAuthResetPasswordApiArg = {
  passwordResetEmail: PasswordResetEmail;
};
export type PostApiV1CoreAuthResetPasswordOtpVerificationApiResponse =
  /** status 201  */ EmailOtpCheckResponse;
export type PostApiV1CoreAuthResetPasswordOtpVerificationApiArg = {
  emailOtpCheck: EmailOtpCheck;
};
export type PostApiV1CoreAuthResetPasswordResetApiResponse =
  /** status 200  */ PasswordReset;
export type PostApiV1CoreAuthResetPasswordResetApiArg = {
  passwordResetEmail: PasswordResetUpdate;
  token: string;
};
export type Login = {
  username: string;
  password: string;
};

export type ResendOtp = {
  email: string;
};
export type EmailOtpCheck = {
  email: string;
  code: string;
};
export type EmailOtpCheckResponse = {
  email: string;
  token: string;
};
export type DetailResponse = {
  detail: boolean;
};
export type TokenRefresh = {
  access: string;
  refresh: string;
};
export type PasswordResetEmail = {
  email: string;
};

export type PasswordResetUpdate = {
  new_password: string;
  confirm_new_password: string;
};

export type PasswordReset = {
  password: string;
};
export const {
  usePostApiV1CoreByAccountTypeAuthMutation,
  usePostApiV1CoreAuthEmailResendOtpMutation,
  usePostApiV1CoreAuthEmailVerifyMutation,
  usePostApiV1CoreAuthLogoutMutation,
  usePostApiV1CoreAuthRefreshMutation,
  usePostApiV1CoreAuthResetPasswordMutation,
  usePostApiV1CoreAuthResetPasswordOtpVerificationMutation,
  usePostApiV1CoreAuthResetPasswordResetMutation,
} = injectedRtkApi;
