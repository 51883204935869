import { toast } from "react-toastify";
import { notify } from "store/slices/NotifyToastSlice";
import organizationSlice from "store/slices/organisation";
import { api } from "./index";
import { Wallet } from "./wallet";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiV1CoreOrganization: build.query<
      GetApiV1CoreOrganizationApiResponse,
      GetApiV1CoreOrganizationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/`,
        params: { ordering: queryArg.ordering, page: queryArg.page },
      }),
    }),
    listApiV1CoreOrganizationPayout: build.query<
      ListApiV1CoreOrganizationPayoutApiResponse,
      ListApiV1CoreOrganizationPayoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.organizationPk}/payout/`,
        params: { ordering: queryArg.ordering, page: queryArg.page },
      }),
    }),
    retrieveApiV1CoreOrganizationPayout: build.query<
      RetrieveApiV1CoreOrganizationPayoutApiResponse,
      RetrieveApiV1CoreOrganizationPayoutApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.organizationPk}/payout/${queryArg.id}/`,
      }),
    }),
    getApiV1CoreOrganizationById: build.query<
      GetApiV1CoreOrganizationByIdApiResponse,
      GetApiV1CoreOrganizationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.id}/`,
      }),
    }),
    putApiV1CoreOrganizationById: build.mutation<
      PutApiV1CoreOrganizationByIdApiResponse,
      PutApiV1CoreOrganizationByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.organization,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          if (!!data) {
            dispatch(organizationSlice.actions.setCurrentOrganization(data));
            let message = "";
            if (data.accepts_webhook) {
              message = "Activation des hooks reussi";
            } else {
              message = "Désactivation des hooks reussi";
            }
            dispatch(
              notify({
                message: message,
                typeToast: toast.TYPE.SUCCESS,
              })
            );
          }
        } catch (err) {
          // `onError` side-effect
          dispatch(
            notify({
              message: "Une erreur s'est produite",
              typeToast: toast.TYPE.ERROR,
            })
          );
        }
      },
    }),
    postApiV1CoreOrganizationByIdActivate: build.mutation<
      PostApiV1CoreOrganizationByIdActivateApiResponse,
      PostApiV1CoreOrganizationByIdActivateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.id}/activate/`,
        method: "POST",
      }),
    }),
    getApiV1CoreOrganizationByIdApiKey: build.query<
      GetApiV1CoreOrganizationByIdApiKeyApiResponse,
      GetApiV1CoreOrganizationByIdApiKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.id}/api_key/`,
        params: {
          page: queryArg.page,
          ordering: queryArg.ordering,
        },
      }),
      providesTags: ["ApiKeyList"],
    }),
    postApiV1CoreOrganizationByIdApiKey: build.mutation<
      PostApiV1CoreOrganizationByIdApiKeyApiResponse,
      PostApiV1CoreOrganizationByIdApiKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.id}/api_key/`,
        method: "POST",
        body: queryArg.body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          if (!!data) {
            dispatch(
              notify({
                message: "Creation de l'api key reussi",
                typeToast: toast.TYPE.SUCCESS,
              })
            );
          }
        } catch (err) {
          // `onError` side-effect
          dispatch(
            notify({
              message: "Une erreur s'est produite",
              typeToast: toast.TYPE.ERROR,
            })
          );
        }
      },
      invalidatesTags: ["ApiKeyList"],
    }),
    postApiV1CoreOrganizationByIdDeactivate: build.mutation<
      PostApiV1CoreOrganizationByIdDeactivateApiResponse,
      PostApiV1CoreOrganizationByIdDeactivateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.id}/deactivate/`,
        method: "POST",
      }),
    }),
    postApiV1CoreOrganizationByIdVerify: build.mutation<
      PostApiV1CoreOrganizationByIdVerifyApiResponse,
      PostApiV1CoreOrganizationByIdVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.id}/verify/`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: true,
});
export { injectedRtkApi as organization };
export type GetApiV1CoreOrganizationApiResponse =
  /** status 200  */ PaginatedOrganizationList;
export type GetApiV1CoreOrganizationApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type ListApiV1CoreOrganizationPayoutApiResponse =
  /** status 200  */ PaginatedPayoutList;
export type ListApiV1CoreOrganizationPayoutApiArg = {
  /** Which field to use when ordering the results. */
  ordering?: string;
  organizationPk: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type RetrieveApiV1CoreOrganizationPayoutApiResponse =
  /** status 200  */ Payout;
export type RetrieveApiV1CoreOrganizationPayoutApiArg = {
  id: string;
  organizationPk: string;
};
export type GetApiV1CoreOrganizationByOrganizationPkWalletTransactionApiResponse =
  /** status 200  */ PaginatedWalletTransactionList;

export type GetApiV1CoreOrganizationByIdApiResponse =
  /** status 200  */ Organization;
export type GetApiV1CoreOrganizationByIdApiArg = {
  id: string;
};
export type PutApiV1CoreOrganizationByIdApiResponse =
  /** status 200  */ Organization;
export type PutApiV1CoreOrganizationByIdApiArg = {
  id: string;
  organization: Partial<Organization>;
};
export type PostApiV1CoreOrganizationByIdActivateApiResponse =
  /** status 200  */ ActionResponse;
export type PostApiV1CoreOrganizationByIdActivateApiArg = {
  id: string;
};
export type GetApiV1CoreOrganizationByIdApiKeyApiResponse =
  /** status 200  */ PaginatedApiKeyList;
export type GetApiV1CoreOrganizationByIdApiKeyApiArg = {
  id: string;
  page?: number;
  ordering?: string;
};
export type PostApiV1CoreOrganizationByIdApiKeyApiResponse =
  /** status 200  */ ApiKeyResponse;
export type PostApiV1CoreOrganizationByIdApiKeyApiArg = {
  id: string;
  body: {
    test: boolean;
    name: string;
  };
};
export type PostApiV1CoreOrganizationByIdDeactivateApiResponse =
  /** status 200  */ ActionResponse;
export type PostApiV1CoreOrganizationByIdDeactivateApiArg = {
  id: string;
};
export type RetrieveApiV1CoreOrganizationPaymentsApiArg = {
  id: string;
};
export type PostApiV1CoreOrganizationByIdVerifyApiResponse =
  /** status 200  */ ActionResponse;
export type PostApiV1CoreOrganizationByIdVerifyApiArg = {
  id: string;
};
export type OrganizationStatusEnum =
  | "active"
  | "verified"
  | "unverified"
  | "inactive";
export type Organization = {
  id: string;
  name: string;
  email: string;
  phone: string;
  city: string;
  country: string;
  status?: OrganizationStatusEnum;
  commission_ratio?: string;
  accepts_webhook?: boolean;
  created: string;
  updated: string;
  wallet: Wallet;
};
export type PaginatedOrganizationList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Organization[];
};
export type PaymentTypeEnum = "request-payment" | "transfer";
export type ModeEnum = "mtn-benin" | "moov-benin";
export type PaymentStatusEnum =
  | "completed"
  | "processing"
  | "pending"
  | "failed";
export type FinancialStatusEnum =
  | "pending"
  | "success"
  | "failed"
  | "refunded"
  | "cancelled";
export type Payment = {
  id: string;
  account_alias: string;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  type: PaymentTypeEnum;
  amount: string;
  reference: string;
  processing_number: string | null;
  mode: ModeEnum;
  status: PaymentStatusEnum;
  financial_status: FinancialStatusEnum;
  completed: string | null;
  processing: string | null;
  failed: string | null;
  created: string;
  updated: string;
  webhook_url?: string | null;
  refunded: string | null;
  test: boolean;
};
export type PaginatedPaymentList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Payment[];
};
export type PayoutStatusEnum = "success" | "pending";
export type Payout = {
  id: string;
  amount: number;
  commission: string;
  reference: string;
  created: string;
  updated: string;
  status: PayoutStatusEnum;
  payments_count: number;
};
export type PaginatedPayoutList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Payout[];
};
export type WalletTransactionTypeEnum = "debit" | "credit" | "fund_card";
export type WalletTransaction = {
  id: string;
  wallet: string;
  amount: string;
  amount_str: string;
  type: WalletTransactionTypeEnum;
  note?: string | null;
  reference?: string;
  created: string;
  updated: string;
  payment: string;
};
export type PaginatedWalletTransactionList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: WalletTransaction[];
};
export type PaginatedApiKeyList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: ApiKey[];
};
export type ActionResponse = {
  detail: boolean;
};
export type ApiKey = {
  id: string;
  name?: string;
  organization: string;
  expiry_date: string;
  revoked: boolean;
  test?: boolean;
  created: string;
};

export type ApiKeyResponse = {
  API_KEY: string;
  PRIVATE_SECRET: string;
};

export const {
  useGetApiV1CoreOrganizationQuery,
  useListApiV1CoreOrganizationPayoutQuery,
  useRetrieveApiV1CoreOrganizationPayoutQuery,
  useGetApiV1CoreOrganizationByIdQuery,
  usePutApiV1CoreOrganizationByIdMutation,
  usePostApiV1CoreOrganizationByIdActivateMutation,
  useGetApiV1CoreOrganizationByIdApiKeyQuery,
  usePostApiV1CoreOrganizationByIdApiKeyMutation,
  usePostApiV1CoreOrganizationByIdDeactivateMutation,
  usePostApiV1CoreOrganizationByIdVerifyMutation,
} = injectedRtkApi;
