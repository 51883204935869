
export type SubjectType= {
    hash:string;
    description:string
}

export const subjectList:SubjectType[] =[
    {
        hash:"1",
        description:"Not Identified"
    },
    {
        hash:"2",
        description:"Closed"
    },
    {
        hash:"3",
        description:"Communicated"
    },
    {
        hash:"4",
        description:"Identified"
    },
    {
        hash:"5",
        description:"Resolved"
    },
    {
        hash:"6",
        description:"Cancelled"
    }
   
]
