// @ts-ignore
import { MutatingDots } from "react-loader-spinner";
import colors from "theme/foundations/colors";
import { Flex } from "@chakra-ui/react";

const Loader = () => {
  return (
    <Flex
      w="full"
      h="full"
      top={0}
      left={0}
      align="center"
      justify="center"
      flexDir="column"
      pointerEvents="none"
      zIndex={800}
      position={"absolute"}
    >
      <Flex w="full" h="full" justify="center" align="center">
        <MutatingDots
          height="100"
          width="100"
          color={colors.primary}
          secondaryColor={colors.primary}
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </Flex>
    </Flex>
  );
};

export default Loader;
