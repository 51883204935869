import { InputHTMLAttributes, ReactNode, useState } from "react";
import {
  ComponentWithAs,
  EditableInputProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export type InputFieldPropsDumbType = InputHTMLAttributes<HTMLInputElement> & {
  placeholder?: string;
  name: string;
  defaultValue?: string | number | readonly string[];
  hasError?: boolean;
  label?: ReactNode;
  error?: string;
  variantTextLabel?: string;
  IconLeft?: React.ReactNode;
  IconRight?: React.ReactNode;
  as?: ComponentWithAs<"input", EditableInputProps>;
  hasShowPasswordIcon?: boolean;
};

const InputFieldDumb = ({
  size: _,
  defaultValue,
  name,
  hasError = false,
  label = "",
  error = "",
  variantTextLabel = "textSmallBold",
  IconLeft,
  IconRight,
  hasShowPasswordIcon = true,
  as,
  ...props
}: InputFieldPropsDumbType) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <FormControl w="full" isInvalid={hasError}>
        <FormLabel htmlFor={name}>
          <Text color="darkPrimary" variant={variantTextLabel}>
            {label}
          </Text>
        </FormLabel>
        <InputGroup>
          {IconLeft && (
            <InputLeftElement
              pointerEvents="none"
              color="primary"
              fontSize="1.2em"
              children={IconLeft}
            />
          )}
          <Input
            _placeholder={{ color: "primary" }}
            defaultValue={defaultValue}
            borderColor="gray"
            w="full"
            as={as}
            pr="4.5rem"
            {...props}
            type={
              props.type == "password"
                ? showPassword
                  ? "text"
                  : "password"
                : "text"
            }
          />
          {IconRight && <InputRightElement children={IconRight} />}
          {props.type === "password" && hasShowPasswordIcon && (
            <IconButton
              variant="ghost"
              minW="30px"
              marginLeft="-30px"
              onClick={() => setShowPassword(!showPassword)}
              aria-label="eye"
              zIndex="docked"
              icon={showPassword ? <Eye /> : <EyeSlash />}
            />
          )}
        </InputGroup>
        {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </>
  );
};
export default InputFieldDumb;
