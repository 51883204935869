export enum LoginStepEnum {
  creditialStep,
  otpStep,
}

export enum RegisterStepEnum {
  ownerStep,
  organisationStep,
  otpStep,
}
