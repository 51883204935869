import { api } from "./index";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiV1CoreOrganizationByOrganizationPkPayment: build.query<
      GetApiV1CoreOrganizationByOrganizationPkPaymentApiResponse,
      GetApiV1CoreOrganizationByOrganizationPkPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.organizationPk}/payment/`,
        params: {
          financial_status: queryArg.financial_status,
          ordering: queryArg.ordering,
          page: queryArg.page,
          reference: queryArg.reference,
          status: queryArg.status,
          type: queryArg.type,
          search: queryArg.search,
        },
      }),
    }),
    retrieveApiV1CoreOrganizationPayment: build.query<
      RetrieveApiV1CoreOrganizationPaymentApiResponse,
      RetrieveApiV1CoreOrganizationPaymentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/organization/${queryArg.organizationPk}/payment/${queryArg.id}/`,
      }),
    }),
  }),
  overrideExisting: true,
});
export { injectedRtkApi as payment };
export type GetApiV1CoreOrganizationByOrganizationPkPaymentApiResponse =
  /** status 200  */ PaginatedPaymentList;
export type GetApiV1CoreOrganizationByOrganizationPkPaymentApiArg = {
  financial_status?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  organizationPk: string;
  /** A page number within the paginated result set. */
  page?: number;
  reference?: string;
  status?: string;
  type?: string;
  search?: string;
};
export type RetrieveApiV1CoreOrganizationPaymentApiResponse =
  /** status 200  */ Payment;
export type RetrieveApiV1CoreOrganizationPaymentApiArg = {
  id: string;
  organizationPk: string;
};

export type GetApiV1OptimusPaymentApiArg = {
  financial_status?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
  reference?: any;
  status?: any;
  type?: any;
};

export type PostApiV1OptimusPaymentByIdRefundApiResponse =
  /** status 200  */ Payment;

export type PaymentTypeEnum = "request-payment" | "transfer";
export type ModeEnum = "mtn-benin" | "moov-benin";
export type PaymentStatusEnum =
  | "completed"
  | "processing"
  | "pending"
  | "failed";
export type FinancialStatusEnum =
  | "pending"
  | "success"
  | "failed"
  | "refunded"
  | "cancelled";
export type Payment = {
  id: string;
  account_alias: string;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  type: PaymentTypeEnum;
  amount: string;
  reference: string;
  processing_number: string | null;
  mode: ModeEnum;
  status: PaymentStatusEnum;
  financial_status: FinancialStatusEnum;
  completed: string | null;
  processing: string | null;
  failed: string | null;
  created: string;
  updated: string;
  webhook_url?: string | null;
  refunded: string | null;
  test: boolean;
};
export type PaginatedPaymentList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Payment[];
};
export const {
  useGetApiV1CoreOrganizationByOrganizationPkPaymentQuery,
  useRetrieveApiV1CoreOrganizationPaymentQuery,
} = injectedRtkApi;
