import { Dropdown, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { DASHBOARD_SETTINGS } from "../constants/routing";
import { usePostApiV1CoreAuthLogoutMutation } from "store/api/auth";

const ProfileMenu = () => {
  const [logout] = usePostApiV1CoreAuthLogoutMutation();

  const menu = (
    <Menu
      items={[
        {
          label: <Link to={DASHBOARD_SETTINGS}>Settings</Link>,
          key: "0",
        },
        {
          type: "divider",
        },
        {
          label: "Logout",
          key: "2",
          onClick: () => logout(),
        },
      ]}
    />
  );
  return (
    <Dropdown.Button icon={<UserOutlined />} overlay={menu}>
      Profile
    </Dropdown.Button>
  );
};

export default ProfileMenu;
