import {
  Box,
  Button,
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import {  useSelector } from "react-redux";
import { RootState } from "store";
import { ChangePassword,  usePostApiV1CoreAccountChangePasswordMutation } from "store/api/account";
import InputField from "uikit/inputs/InputField";

const Settings = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector((state: RootState) => state.auth.user);
  const [changePassword, {isLoading}]=usePostApiV1CoreAccountChangePasswordMutation()
  const handleChangePassword=(values:ChangePassword)=>{
    changePassword({changePassword:values}).then(
      (data)=>{
        if(data){
          onClose()
        }
      }
    )
  }
  return (
    <>
      <Box w="400px">
        <Box marginTop="84px" marginBottom="43px">
          <Text variant="subTitleBold">Setting </Text>
          <Text variant="subTitleBold" mt="27px" fontWeight="500">
            Profil
          </Text>
        </Box>
        <Flex justifyContent="space-between">
          <Text variant="textMedium">Email</Text>
          <Box>
            <Link color="primary" href="mailto:celda@gmail.com">
              {user?.email}
            </Link>
          </Box>
        </Flex>
        <Flex marginTop="45px" justifyContent="space-between">
          <Text variant="textMedium">Name</Text>
          <Flex justifyContent="flex-start">
            <Text variant="textMedium">
              {`${user?.first_name} ${user?.last_name}`}
            </Text>
          </Flex>
        </Flex>
        <Flex marginTop="45px" justifyContent="space-between">
          <Text variant="textMedium">Password</Text>
          <HStack>
            <Text variant="textMedium">........... </Text>
            <Text
              variant="textMedium"
              color="primary"
              cursor="pointer"
              onClick={onOpen}
            >
              Change
            </Text>
          </HStack>
        </Flex>
      </Box>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik<ChangePassword>
            initialValues={{
              current_password: "",
              new_password: "",
              confirm_new_password:"",
            }}
            onSubmit={(values) => handleChangePassword(values)}
          >
            <Form>
              <ModalHeader textAlign="center" marginTop="50px" color="primary">
                <Text variant="subTitle">Password change</Text>
              </ModalHeader>
              <ModalCloseButton
                bg="stateError"
                borderRadius="50px"
                color="white"
              />
              <ModalBody mt="20px" pb="50px">
                <VStack>
                <InputField
                  name="current_password"
                  label="Enter your current password"
                  type="password"
                />

                <InputField
                  name="new_password"
                  label="Enter your new password"
                  type="password"

                />   

                <InputField
                name="confirm_new_password"
                label="Confirm your new password"
                type="password"

              />
                </VStack>
               
              </ModalBody>

              <ModalFooter>
                <Button type="submit" isLoading={isLoading} mb="20px" w="full" colorScheme="primary">
                  Update your password
                </Button>
              </ModalFooter>
            </Form>
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Settings;
