import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { menuList } from "constants/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "assets/transfa_logo_long.png";
import { DASHBOARD_HOME } from "constants/routing";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box bg="lightGray" height="100vh" w="50%">
      <Box display="flex" cursor="pointer" onClick={()=>navigate(DASHBOARD_HOME)} flexDirection="row">
        <Image w="60%" marginX="15%" marginTop="5%" src={Logo} />
      </Box>
      <Box height="100%" marginTop="15%">
        {menuList.map(({ link, label, icon: Icon }, index) => (
          <Flex
            w="full"
            height="6%"
            key={index}
            bg={location.pathname == link ? "white" : ""}
            color={location.pathname == link ? "primary" : "gray4"}
            justifyContent="center"
            alignItems="center"
            onClick={() => navigate(link)}
            cursor="pointer"
          >
            <Box w="40px">
              <Icon />
            </Box>
            <Box w="100px">
              <Text
                color={location.pathname == link ? "primary" : "gray4"}
                variant="textBody"
              >
                {label}
              </Text>
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default SideBar;
