import type { ComponentStyleConfig } from "@chakra-ui/theme";
import solidButtonVariants from "theme/uiKit/inputs/buttonVariants/solids";
import outlineButtonVariants from "theme/uiKit/inputs/buttonVariants/outlines";

const Button: ComponentStyleConfig = {
  baseStyle: {
    lineHeight: "1.2",
    borderRadius: "6px",
    fontWeight: "700",
    transitionProperty: "common",
    transitionDuration: "normal",
    _disabled: {
      opacity: 0.4,
      cursor: "not-allowed",
      boxShadow: "none",
    },
    _hover: {
      _disabled: {
        bg: "initial",
      },
    },
  },
  variants: {
    ...solidButtonVariants,
    ...outlineButtonVariants,
  },
  sizes: {
    lg: {
      h: "48px",
      minW: "100px",
      fontSize: "18px",
      lineHeight: "28px",
      px: "15px",
      py: "10px",
    },
    md: {
      h: "40px",
      minW: "100px",
      fontSize: "16px",
      lineHeight: "24px",
      px: "32px",
      py: "8px",
    },
    sm: {
      h: "29px",
      borderRadius: "8px",
      minW: "100px",
      fontSize: "14px",
      lineHeight: "21px",
      px: "16px",
      py: "4px",
    },
    xs: {
      h: "auto",
      w: "auto",
      textAlign: "center",
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
};

export default Button;
