import { notify } from "store/slices/NotifyToastSlice";
import { api } from "./index";
import { Wallet } from "./wallet";
import { toast } from "react-toastify";

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiV1CoreByAccountTypeAccount: build.query<
      GetApiV1CoreByAccountTypeAccountApiResponse,
      GetApiV1CoreByAccountTypeAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/account/`,
        params: { ordering: queryArg.ordering, page: queryArg.page },
      }),
    }),
    getApiV1CoreByAccountTypeAccountAndId: build.query<
      GetApiV1CoreByAccountTypeAccountAndIdApiResponse,
      GetApiV1CoreByAccountTypeAccountAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/account/${queryArg.id}/`,
      }),
    }),
    putApiV1CoreByAccountTypeAccountAndId: build.mutation<
      PutApiV1CoreByAccountTypeAccountAndIdApiResponse,
      PutApiV1CoreByAccountTypeAccountAndIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/account/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.owner,
      }),
    }),
    postApiV1CoreByAccountTypeAccountAndIdActivate: build.mutation<
      PostApiV1CoreByAccountTypeAccountAndIdActivateApiResponse,
      PostApiV1CoreByAccountTypeAccountAndIdActivateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/account/${queryArg.id}/activate/`,
        method: "POST",
      }),
    }),
    postApiV1CoreByAccountTypeAccountAndIdDeactivate: build.mutation<
      PostApiV1CoreByAccountTypeAccountAndIdDeactivateApiResponse,
      PostApiV1CoreByAccountTypeAccountAndIdDeactivateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/account/${queryArg.id}/deactivate/`,
        method: "POST",
      }),
    }),
    postApiV1CoreByAccountTypeAccountAndIdSuspend: build.mutation<
      PostApiV1CoreByAccountTypeAccountAndIdSuspendApiResponse,
      PostApiV1CoreByAccountTypeAccountAndIdSuspendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/account/${queryArg.id}/suspend/`,
        method: "POST",
      }),
    }),
    postApiV1CoreByAccountTypeAuth: build.mutation<
      PostApiV1CoreByAccountTypeAuthApiResponse,
      PostApiV1CoreByAccountTypeAuthApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/auth/`,
        method: "POST",
        body: queryArg.login,
      }),
    }),
    postApiV1CoreAccountChangePassword: build.mutation<
      PostApiV1CoreAuthChangePasswordApiResponse,
      PostApiV1CoreAuthChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/auth/change_password/`,
        method: "POST",
        body: queryArg.changePassword,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(
              notify({
                message: "Update done",
                typeToast: toast.TYPE.SUCCESS,
              })
            );
          }
        } catch (err) {
          // `onError` side-effect
          dispatch(
            notify({
              message: "Update fail. Check your password",
              typeToast: toast.TYPE.ERROR,
            })
          );
        }
      },
    }),
    postApiV1CoreByAccountTypeRegister: build.mutation<
      PostApiV1CoreByAccountTypeRegisterApiResponse,
      PostApiV1CoreByAccountTypeRegisterApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/${queryArg.accountType}/register/`,
        method: "POST",
        body: queryArg.register,
      }),
    }),
  }),
  overrideExisting: true,
});
export { injectedRtkApi as account };
export type GetApiV1CoreByAccountTypeAccountApiResponse =
  /** status 200  */ PaginatedOwnerList;
export type GetApiV1CoreByAccountTypeAccountApiArg = {
  accountType: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** A page number within the paginated result set. */
  page?: number;
};
export type GetApiV1CoreByAccountTypeAccountAndIdApiResponse =
  /** status 200  */ Owner;
export type GetApiV1CoreByAccountTypeAccountAndIdApiArg = {
  accountType: string;
  /** A unique integer value identifying this owner. */
  id: number;
};
export type PutApiV1CoreByAccountTypeAccountAndIdApiResponse =
  /** status 200  */ Owner;
export type PutApiV1CoreByAccountTypeAccountAndIdApiArg = {
  accountType: string;
  /** A unique integer value identifying this owner. */
  id: number;
  owner: Owner;
};
export type PostApiV1CoreByAccountTypeAccountAndIdActivateApiResponse =
  /** status 200  */ ActionResponse;
export type PostApiV1CoreByAccountTypeAccountAndIdActivateApiArg = {
  accountType: string;
  /** A unique integer value identifying this owner. */
  id: number;
};
export type PostApiV1CoreByAccountTypeAccountAndIdDeactivateApiResponse =
  /** status 200  */ ActionResponse;
export type PostApiV1CoreByAccountTypeAccountAndIdDeactivateApiArg = {
  accountType: string;
  /** A unique integer value identifying this owner. */
  id: number;
};
export type PostApiV1CoreByAccountTypeAccountAndIdSuspendApiResponse =
  /** status 200  */ ActionResponse;
export type PostApiV1CoreByAccountTypeAccountAndIdSuspendApiArg = {
  accountType: string;
  /** A unique integer value identifying this owner. */
  id: number;
};
export type PostApiV1CoreByAccountTypeAuthApiResponse = /** status 201  */ {
  username: string;
};
export type PostApiV1CoreByAccountTypeAuthApiArg = {
  accountType: string;
  login: Login;
};
export type PostApiV1CoreByAccountTypeRegisterApiResponse =
  /** status 201  */ User;
export type PostApiV1CoreByAccountTypeRegisterApiArg = {
  accountType: string;
  register: {
    first_name: string;
    last_name: string;
    phone: string;
    organization: Partial<Organization>;
    email: string;
    password: string;
  };
};
export type OrganizationStatusEnum =
  | "active"
  | "verified"
  | "unverified"
  | "inactive";
export type Organization = {
  id: string;
  name: string;
  email: string;
  phone: string;
  city: string;
  country: string;
  status?: OrganizationStatusEnum;
  commission_ratio?: string;
  accepts_webhook?: boolean;
  created: string;
  updated: string;
  wallet: Wallet;
};
export type Owner = {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  phone?: string | null;
  is_suspended?: boolean;
  is_active: boolean;
  organization: Organization;
  organization_root: Organization;
  created: string;
  updated: string;
  email: string;
  password: string;
};
export type PaginatedOwnerList = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results?: Owner[];
};
export type ActionResponse = {
  detail: boolean;
};
export type Login = {
  username: string;
  password: string;
};
export type User = {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  phone?: string | null;
  email: string;
  is_active?: boolean;
  created: string;
  updated: string;
  organization_root: Organization;
};

export type PostApiV1CoreAuthChangePasswordApiResponse =
  /** status 201  */ ChangePassword;
export type PostApiV1CoreAuthChangePasswordApiArg = {
  changePassword: ChangePassword;
};

export type ChangePassword = {
  current_password: string;
  new_password: string;
  confirm_new_password: string;
};

export const {
  useGetApiV1CoreByAccountTypeAccountQuery,
  useGetApiV1CoreByAccountTypeAccountAndIdQuery,
  usePutApiV1CoreByAccountTypeAccountAndIdMutation,
  usePostApiV1CoreByAccountTypeAccountAndIdActivateMutation,
  usePostApiV1CoreByAccountTypeAccountAndIdDeactivateMutation,
  usePostApiV1CoreByAccountTypeAccountAndIdSuspendMutation,
  usePostApiV1CoreByAccountTypeAuthMutation,
  usePostApiV1CoreByAccountTypeRegisterMutation,
  usePostApiV1CoreAccountChangePasswordMutation,
} = injectedRtkApi;
