const typography = {
  fontWeights: {
    normal: 400,
    bold: 700,
  },

  fonts: {
    body: "Inter",
  },
};

export default typography;
