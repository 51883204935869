import { Box, Text } from "@chakra-ui/react";

const PageNotFound = () => {
  return (
    <Box>
      <Text>Page not found </Text>
    </Box>
  );
};

export default PageNotFound;
