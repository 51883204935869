import { ForgotPaswordMailStepValidation, ForgotPaswordUpdatePasswordValidation, otpStepValidation } from "utils/ValidationScheme";

export enum ForgotPasswordStepEnum {
    mailStep, 
    otpStep,
    passwordUpdateStep
  }  


export const forgotPasswordValidation ={
    [ForgotPasswordStepEnum.mailStep]:ForgotPaswordMailStepValidation,
    [ForgotPasswordStepEnum.otpStep]:otpStepValidation,
    [ForgotPasswordStepEnum.passwordUpdateStep]:ForgotPaswordUpdatePasswordValidation
}

export const forgotPasswordinitialValues ={
    [ForgotPasswordStepEnum.mailStep]:{
        email:""
    } as MailStepType,
    [ForgotPasswordStepEnum.otpStep]:{
        code:""
    } as OtpStepType,
    [ForgotPasswordStepEnum.passwordUpdateStep]:{
        new_password: "",
        confirm_new_password:""
    } as UpdatePasswordStepType
}

export type MailStepType = {
    email:string
}
export type OtpStepType = {
    code:string
}

export type UpdatePasswordStepType = {
    new_password:string,
    confirm_new_password:string
}
export type ForgotPasswordFormType = MailStepType & OtpStepType & UpdatePasswordStepType