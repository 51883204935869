import { Input } from "antd";

const { Search } = Input;

type searchFilter = {
  handleSearch: (param: string, value: string) => void;
};

const SearchFilter = ({ handleSearch }: searchFilter) => {
  return (
    <Search
      onSearch={(value) => handleSearch("search", value)}
      placeholder="Search"
      allowClear
      style={{ width: 200 }}
    />
  );
};

export default SearchFilter;
