import { Box, Text, HStack } from "@chakra-ui/react";
import StatsCard from "uikit/components/StatsCard";
import { useSelector } from "react-redux";
import { RootState } from "store/index";
import { useGetApiV1CoreOrganizationByIdQuery } from "store/api/organization";
import WalletTransactions from "components/Tables/WalletTransactions";

function Home() {
  const user = useSelector((state: RootState) => state.auth.user);

  const { data: organizationData } = useGetApiV1CoreOrganizationByIdQuery({
    id: user?.organization_root.id || "",
  });
  return (
    <Box w="full">
      <Box>
        <Text variant="textBodyBold">
          Hey {user?.first_name} {user?.last_name}
        </Text>
        <Text>See your statistics since you use Transfa</Text>
      </Box>
      <HStack marginY="4%">
        <StatsCard
          color="success2"
          value={organizationData ? organizationData.wallet.balance_str : "---"}
          description="Balance"
        />
        <StatsCard
          color="error2"
          value={organizationData?.wallet?.pending_balance_str || "---"}
          description="Pending balance"
        />
      </HStack>
      <Text variant="textBodyBold">Wallet transactions</Text>
      <WalletTransactions />
    </Box>
  );
}

export default Home;
