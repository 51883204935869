// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "@chakra-ui/react";

interface ClipBoardBtnInterface {
  text?: string;
  onCopy: () => void;
  labeTextCopy?: string;
  labelText: string;
  variant?: string;
}

const ClipBoardBtn = ({
  text = "",
  onCopy,
  labelText = "Copy",
}: ClipBoardBtnInterface) => {
  return (
    <CopyToClipboard text={text} onCopy={() => onCopy()}>
      <Button w="full" variant="outline">
        {labelText}
      </Button>
    </CopyToClipboard>
  );
};

export default ClipBoardBtn;
