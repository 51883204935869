import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast, TypeOptions } from "react-toastify";

type State = {};

const initialState: State = {};

const notifySlice = createSlice({
  name: "notifySlice",
  initialState,
  reducers: {
    notify(
      _state: State,
      action: PayloadAction<{ message: string; typeToast: TypeOptions }>
    ) {
      const options = {
        autoClose: 1000,
        type: action.payload.typeToast,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER,
        pauseOnHover: false,
      };
      toast(action.payload.message, { ...options });
    },
  },
});

const { actions } = notifySlice;
// Extract and export each action creator by name
export const { notify } = actions;
// Export the reducer, either as a default or named export
export default notifySlice;
