import { Box, Flex, Image, Button, Text, Link } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import bgImage from "assets/transfa_bg.svg";
import transfaLogo from "assets/transfa_logo_long.png";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { ArrowRightCircle } from "react-bootstrap-icons";
import { DASHBOARD_HOME, LOGIN } from "constants/routing";
import { useNavigate } from "react-router-dom";
import authSlice from "store/slices/auth";
import { usePostApiV1CoreAuthRefreshMutation } from "store/api/auth";
import Loader from "uikit/components/Loader";

const BackgroundLayout = ({ children }: { children: React.ReactNode }) => {
  const account = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkRefresh, { isLoading }] = usePostApiV1CoreAuthRefreshMutation();
  const [hasValidToken, setHasValidToken] = useState(false);
  const onLogin = () => {
    dispatch(authSlice.actions.setLogout());
  };

  const handleCheckRefreshToken = async () => {
    checkRefresh({
      tokenRefresh: { access: account?.access, refresh: account?.refresh },
    }).then((data) => {
      // @ts-ignore
      if (data?.error?.data?.errors) {
        setHasValidToken(false);
      } else {
        setHasValidToken(true);
      }
    });
  };

  useEffect(() => {
    handleCheckRefreshToken();
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Flex
      bgImage={bgImage}
      bgSize="cover"
      bgRepeat="no-repeat"
      height="100vh"
      overflowX="hidden"
      width="full"
    >
      <Box
        display={{
          sm: "none",
          md: "block",
        }}
        position="absolute"
        top="10px"
        left="30px"
      >
        <Image height="50px" src={transfaLogo} />
      </Box>
      <Box w="full" h="full" mt="30px">
        {hasValidToken ? (
          <Flex justifyContent="center" alignItems="center" w="100vw" h="100vh">
            <Flex
              bg="white"
              alignItems="center"
              minW={{ base: "200px", md: "300px" }}
              minH="250px"
              p="30px"
              m="10px"
              flexDirection="column"
              borderRadius="8px"
            >
              <Text variant="textBody" mb="8%">
                Voulez-vous continuer avec le compte{" "}
                <span style={{ fontWeight: "bold" }}>
                  {account.user?.first_name} {account.user?.last_name}
                </span>{" "}
                ?
              </Text>
              <Button
                onClick={() => navigate(DASHBOARD_HOME)}
                mb="30px"
                rightIcon={<ArrowRightCircle />}
              >
                Continuer
              </Button>

              <Link
                color="primary"
                textDecoration="underline"
                onClick={onLogin}
                href={LOGIN}
              >
                Se connecter avec un autre compte
              </Link>
            </Flex>
          </Flex>
        ) : (
          children
        )}
      </Box>
    </Flex>
  );
};

export default BackgroundLayout;
