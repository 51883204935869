import { useEffect } from "react";
import { useField } from "formik";

import { ComponentWithAs, EditableInputProps } from "@chakra-ui/react";
import InputFieldDumb, { InputFieldPropsDumbType } from "uikit/inputs/dumb/InputField";

type IInputFieldProps = InputFieldPropsDumbType & {
  label?: string;
  placeholder?: string;
  name: string;
  defaultValue?: string | number | readonly string[];
  partlyControlledValue?: string;
  validate?: (_: any) => undefined | string | Promise<any>;
  validateOnChange?: boolean;
  variantTextLabel?: string;
  as?: ComponentWithAs<"input", EditableInputProps>;
};

const InputField = ({
  label,
  validateOnChange = true,
  size: _,
  defaultValue,
  partlyControlledValue,
  variantTextLabel,
  as,
  ...props
}: IInputFieldProps) => {
  const [field, { error, touched }, { setValue, setTouched }] = useField(props);
  const onChange = (event: React.ChangeEvent<any>) => {
    setValue(event.target.value, validateOnChange);
    setTouched(true, false);
  };
  useEffect(() => {
    //for case defaultValue is set
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue]);
  //for case when i want input to be partly controlled
  useEffect(() => {
    if (partlyControlledValue) {
      setValue(partlyControlledValue);
    }
  }, [partlyControlledValue, setValue]);

  const hasError = !!error && touched;
  return (
    <InputFieldDumb
      {...field}
      {...props}
      id={field.name}
      defaultValue={defaultValue}
      placeholder={props.placeholder}
      onChange={onChange}
      label={label}
      hasError={hasError}
      error={error}
      variantTextLabel={variantTextLabel}
      as={as}
    />
  );
};
export default InputField;
