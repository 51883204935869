import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import InputFieldDumb from "uikit/inputs/dumb/InputField";
import { RootState } from "store";
import {
  ApiKeyResponse,
  useGetApiV1CoreOrganizationByIdApiKeyQuery,
  usePostApiV1CoreOrganizationByIdApiKeyMutation,
  usePutApiV1CoreOrganizationByIdMutation,
} from "store/api/organization";
import { useState } from "react";
import { formatDate } from "utils/helpers";
import ClipBoardBtn from "uikit/inputs/ClipboardButton";
import { isEmpty } from "lodash";
import { notify } from "store/slices/NotifyToastSlice";
import { toast } from "react-toastify";
import { ColumnsType } from "antd/es/table";
import { ELEMENT_PER_PAGE } from "constants/index";
import { Checkbox, Table } from "antd";

const columns: ColumnsType<any> = [
  {
    title: "Name",
    dataIndex: "name",
    sorter: true,
    render: (value) => <Text>{value}</Text>,
  },
  {
    title: "Status",
    dataIndex: "revoked",
    render: (value) => {
      if (value) {
        return <Text color="stateError">Revoked</Text>;
      }
      return <Text color="stateSuccess">Active</Text>;
    },
  },
  {
    title: "Test",
    dataIndex: "test",
    render: (value) => {
      if (value) {
        return <Checkbox checked={value} disabled={true} />;
      }
      return "";
    },
  },
  {
    title: "Expiry date",
    dataIndex: "expiry_date",
    sorter: true,
    render: (value) => <Text>{formatDate(value)}</Text>,
  },
];

const Developers = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [apiKeyName, setApiKeyName] = useState("");
  const [apiKeyData, setApiKeyData] = useState<ApiKeyResponse>();
  const [hasTest, setHasTest] = useState(true);
  const dispatch = useDispatch();
  const organization = useSelector(
    (state: RootState) => state.organization.currentOrganization
  );

  const [page, setPage] = useState(1);
  const organizationId = !!organization ? organization.id : "";
  const { data: apiKeys, isFetching: isFetchingApiKey } =
    useGetApiV1CoreOrganizationByIdApiKeyQuery({
      id: organizationId,
      page: page,
    });

  const [createApiKey, { isLoading, isSuccess }] =
    usePostApiV1CoreOrganizationByIdApiKeyMutation();

  const [enableHook] = usePutApiV1CoreOrganizationByIdMutation();

  const handleCreateApiKey = () => {
    createApiKey({
      id: organizationId,
      body: {
        test: hasTest,
        name: apiKeyName,
      },
    }).then((response) => {
      // @ts-ignore
      if (!!response.data) {
        // @ts-ignore
        const { data } = response;
        setApiKeyData(data);
      }
    });
  };

  const handleEnableHook = (e: React.ChangeEvent<any>) => {
    enableHook({
      id: organizationId,
      organization: {
        accepts_webhook: e.target.checked,
      },
    });
  };
  const textToCopy = (apiKeys?: ApiKeyResponse) => {
    if (!isEmpty(apiKeys)) {
      const text = `API KEY: ${apiKeys.API_KEY} \n PRIVATE SECRET: ${apiKeys.PRIVATE_SECRET} `;
      return text;
    }
    return "Nothing to copy";
  };

  const handleCopy = () => {
    dispatch(notify({ message: "Copy done!", typeToast: toast.TYPE.SUCCESS }));
  };

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);
    window.scrollTo({ top: 70, behavior: "smooth" });
  };

  return (
    <>
      <Box w="400px">
        <Box marginY="24px">
          <Text variant="subTitleBold">Developers</Text>
        </Box>
        <Box>
          <Text variant="textBodyBold">Webhook</Text>
          <Flex justifyContent="space-between">
            <Text color="darkPrimary">Accept webhooks</Text>
            <Text color="darkPrimary">
              <Switch
                bg="gray"
                name="hasUsedWebHook"
                color="primary"
                borderRadius={12}
                defaultChecked={!!organization?.accepts_webhook}
                checked={!!organization?.accepts_webhook}
                onChange={(e) => handleEnableHook(e)}
              />
            </Text>
          </Flex>
        </Box>
        <Text marginTop="24px" variant="textBodyBold">
          API Keys
        </Text>
        <Flex marginY="24px">
          <Button onClick={onOpen}>Generate an API Key</Button>
        </Flex>

        <Box>
          <Table
            columns={columns}
            dataSource={apiKeys?.results}
            loading={isFetchingApiKey}
            pagination={{
              onChange: handlePageChange,
              current: page,
              total: apiKeys?.count,
              pageSize: ELEMENT_PER_PAGE,
              showSizeChanger: false,
            }}
          />
        </Box>
      </Box>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" marginTop="50px" color="primary">
            <Text variant="subTitle">Generate Api key</Text>
          </ModalHeader>
          <ModalCloseButton bg="stateError" borderRadius="50px" color="white" />
          <ModalBody mt="20px" pb="50px">
            {!isSuccess ? (
              <>
                <Box>
                  <InputFieldDumb
                    value={apiKeyName}
                    onChange={(e) => setApiKeyName(e.target.value)}
                    name="api_key_name"
                    label="Name of key"
                  />
                </Box>
                <Box>
                  <Flex mt="20px" justifyContent="space-between">
                    <Text color="textBold">Test API KEY ?</Text>
                    <Text color="darkPrimary">
                      <Switch
                        bg="gray"
                        name="hasUsedWebHook"
                        color="primary"
                        borderRadius={15}
                        size="lg"
                        onChange={() => setHasTest(!hasTest)}
                        checked={hasTest}
                        defaultChecked={true}
                      />
                    </Text>
                  </Flex>
                </Box>
              </>
            ) : (
              <VStack>
                <InputFieldDumb
                  value={apiKeyData?.API_KEY}
                  name="api_key"
                  disabled={true}
                  label="Api key"
                />
                <InputFieldDumb
                  value={apiKeyData?.PRIVATE_SECRET}
                  name="api_key"
                  disabled={true}
                  label="Private key"
                />
              </VStack>
            )}
          </ModalBody>

          <ModalFooter>
            {isSuccess ? (
              <ClipBoardBtn
                text={textToCopy(apiKeyData)}
                labelText="Click here to copy"
                onCopy={() => handleCopy()}
              />
            ) : (
              <Button
                onClick={() => handleCreateApiKey()}
                mb="20px"
                w="full"
                colorScheme="primary"
                isLoading={isLoading}
              >
                Generate api key
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Developers;
