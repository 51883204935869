import { Button, Select, SelectProps } from "antd";
import { ClearOutlined } from "@ant-design/icons";

type selectFilter = {
  placeholder: string;
  options: SelectProps["options"];
  value: string | null;
  handleFiltering: (value: string) => void;
  width?: number | string;
};

const SelectFilter = (props: selectFilter) => {
  const { placeholder, value, options, handleFiltering } = props;
  return (
    <>
      <Select
        placeholder={placeholder}
        value={value}
        style={{ width: 200 }}
        onChange={(value) => handleFiltering(value)}
        options={options}
      />
      <Button onClick={() => handleFiltering("")} icon={<ClearOutlined />} />
    </>
  );
};

export default SelectFilter;
