import { useEffect } from "react";
import { User } from "store/api/account";

type UserTye = {
  user: User;
};

const ChatwootWidget = (props: UserTye) => {
  useEffect(() => {
    // @ts-ignore
    window.chatwootSettings = {
      hideMessageBubble: false,
      position: "right", // This can be left or right
      locale: "en", // Language to be set
      type: "standard", // [standard, expanded_bubble]
    };

    (function (d, t) {
      let BASE_URL = "https://app.chatwoot.com";
      let g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      // @ts-ignore
      g.src = BASE_URL + "/packs/js/sdk.js";
      // @ts-ignore
      g.defer = true;
      // @ts-ignore
      g.async = true;
      // @ts-ignore
      s.parentNode.insertBefore(g, s);

      g.onload = function () {
        // @ts-ignore
        window.chatwootSDK.run({
          websiteToken: "mD45eLwpjrZhFUZ1iRHJ1Y92",
          baseUrl: BASE_URL,
        });

        const { name, phone, id, email } = props.user;

        // @ts-ignore
        window.$chatwoot.setUser(id, {
          email: email,
          name: name,
          phone_number: phone,
        });
      };
    })(document, "script");
  }, []);

  return null;
};

export default ChatwootWidget;
