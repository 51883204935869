import { Flex, Text } from "@chakra-ui/react";

interface StatsCardType {
  color: string;
  value: string | number;
  description: string;
}

const StatsCard = ({
  color = "success2",
  value,
  description,
}: StatsCardType) => {
  return (
    <Flex
      borderRadius="7px"
      w="full"
      bg={color}
      minHeight="240px"
      direction="column"
      //justifyContent="center"
      alignItems="center"
    >
      <Text marginTop="59px" marginBottom="45px" variant="title">
        {value}
      </Text>
      <Text variant="subTitle" color="gray4">
        {" "}
        {description}{" "}
      </Text>
    </Flex>
  );
};
export default StatsCard;
