import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "./api";
import authSlice from "./slices/auth";
import notifySlice from "./slices/NotifyToastSlice";
import organizationSlice from "./slices/organisation";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  organization: organizationSlice.reducer,
  notify: notifySlice.reducer,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 1,
    storage: storage,
    blacklist: [api.reducerPath],
  },
  rootReducer
);

const middlewareHandler = (getDefaultMiddleware: any) => {
  const middlewareList = [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    api.middleware,
  ];
  return middlewareList;
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: function (getDefaultMiddleware) {
    return middlewareHandler(getDefaultMiddleware);
  },
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>;

export default store;
