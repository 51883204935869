import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import moment from "moment";
import { toast } from "react-toastify";
import store from "store";
import { notify } from "store/slices/NotifyToastSlice";

export type ErrorResponseType = {
  attr: string;
  code: string;
  detail: string;
};
export interface ResponseQuery {
  data: {
    errors: ErrorResponseType[];
    type: string;
  };
}
export type HasErrorType = {
  response: ResponseQuery;
  onError: (details: string, key?: string) => void;
  displayToast?: boolean;
};
export const hasError = (fn: HasErrorType) => {
  const { response, onError, displayToast = true } = fn;
  if (response?.data?.errors?.length) {
    const error = response?.data.errors
      ?.map((error) => error.detail)
      .join("\n");
    if (displayToast) {
      store.dispatch(
        notify({
          message: error,
          typeToast: toast.TYPE.ERROR,
        })
      );
    }
    onError(error);
  } else {
    store.dispatch(
      notify({
        message: `An error has occured. Please check your connection`,
        typeToast: toast.TYPE.ERROR,
      })
    );
  }
};

export const formatDate = (date: string) => {
  if (!date) {
    return "---";
  }
  return moment(date).format("DD-MM-YY hh:mm");
};

export const formatCurrency = (currency: string) =>
  new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currency,
  });

export const listSelectOptions = (options: string[]) =>
  options.map((value) => ({
    label: value,
    value: value,
  }));

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export const isFetchBaseQueryError = (
  error: unknown
): error is FetchBaseQueryError => {
  return typeof error === "object" && error != null && "status" in error;
};

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export const isErrorWithMessage = (
  error: unknown
): error is { data: ErrorResponseType[] } => {
  return (
    typeof error === "object" &&
    error != null &&
    "data" in error &&
    (error as { data: ErrorResponseType[] }).data.length > 0
  );
};
