import { Form, Formik } from "formik";
import { usePostApiV1CoreByAccountTypeAuthMutation } from "store/api/account";
import { loginValidation } from "utils/ValidationScheme";
import InputField from "uikit/inputs/InputField";
import {
  Box,
  Button,
  Flex,
  Spacer,
  Text,
  VStack,
  Link,
} from "@chakra-ui/react";
import { LoginStepEnum } from "configs/login";
import { useEffect, useState } from "react";
import OtpInput from "uikit/inputs/OtpInputField";
import {
  usePostApiV1CoreAuthEmailResendOtpMutation,
  usePostApiV1CoreAuthEmailVerifyMutation,
} from "store/api/auth";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_HOME, FORGOT_PASSWORD, REGISTER } from "constants/routing";
import { hasError, ResponseQuery } from "utils/helpers";
import defaultPalette from "theme/foundations/colors";
import HeaderAuth from "components/authentication/HeaderAuth";
import { useDispatch } from "react-redux";
import { notify } from "store/slices/NotifyToastSlice";
import { toast } from "react-toastify";

function Login() {
  const [login, { isLoading, data }] =
    usePostApiV1CoreByAccountTypeAuthMutation();
  const [verifyOtp, { isLoading: isLoadingVerifyOtp }] =
    usePostApiV1CoreAuthEmailVerifyMutation();
  const [resendOtp, { isLoading: isLoadingResendOtp }] =
    usePostApiV1CoreAuthEmailResendOtpMutation();

  const [step, setStep] = useState<LoginStepEnum>(LoginStepEnum.creditialStep);
  const [errorMessage, setErrorMessage] = useState("");
  const onNext = () => {
    disptach(
      notify({
        message: `Otp has sent to your mail`,
        typeToast: toast.TYPE.SUCCESS,
      })
    );
    setStep(LoginStepEnum.otpStep);
  };
  const navigate = useNavigate();
  const disptach = useDispatch();
  const handleLogin = async (username: string, password: string) => {
    try {
      const loginData = await login({
        accountType: "owner",
        login: { username, password },
      }).unwrap();
      if (loginData.username) {
        onNext();
      }
    } catch (err) {
      hasError({
        response: err as ResponseQuery,
        onError: setErrorMessage,
        displayToast: false,
      });
    }
  };

  useEffect(() => {
    if (step === 1) {
      if (errorMessage) {
        setErrorMessage("");
      }
    }
  }, [step, errorMessage]);
  const handleResendOtp = async () => {
    if (data) {
      try {
        await resendOtp({
          resendOtp: { email: data?.username },
        }).unwrap();
      } catch (err) {
        hasError({
          response: err as ResponseQuery,
          onError: setErrorMessage,
          displayToast: false,
        });
      }
    }
  };
  const CredentialStep = (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      onSubmit={(values) => {
        handleLogin(values.username, values.password);
      }}
      validationSchema={loginValidation}
    >
      <Form>
        <Flex mb="30px">
          <Text color="darkPrimary" variant="title1">
            Sign In
          </Text>
        </Flex>
        <VStack>
          <InputField label="Email" name="username" type="text" />
          <Spacer />
          <InputField
            label="Password"
            hasShowPasswordIcon={true}
            name="password"
            type="password"
          />
        </VStack>
        {errorMessage && (
          <Text my="3px" color="stateError">
            {errorMessage}
          </Text>
        )}
        <Button isLoading={isLoading} my="15px" w="full" type="submit">
          Sign in
        </Button>
        <Flex justifyContent="end" my="10px" w="full">
          <Box>
            <Text textAlign="end">
              <Link color="primary" href={FORGOT_PASSWORD}>
                Forgot password
              </Link>
            </Text>
          </Box>
        </Flex>

        <Flex justifyContent="center" my="10px" w="full">
          <Text textAlign="end">
            Don't have an account?{" "}
            <Link color="primary" href={REGISTER}>
              Register{" "}
              <span
                style={{
                  textDecoration: "underline",
                  color: defaultPalette.primary,
                }}
              >
                here
              </span>
              .
            </Link>
          </Text>
        </Flex>
      </Form>
    </Formik>
  );
  const OtpStep = (
    <Formik
      initialValues={{
        otp: "",
      }}
      onSubmit={async (values) => {
        if (!!data) {
          if (data) {
            try {
              const verifyData = await verifyOtp({
                emailOtpCheck: {
                  email: data.username,
                  code: values.otp,
                },
              }).unwrap();
              if (verifyData.access) {
                navigate(DASHBOARD_HOME);
              }
            } catch (err) {
              hasError({
                response: err as ResponseQuery,
                onError: setErrorMessage,
                displayToast: true,
              });
            }
          }
        }
      }}
    >
      <Form>
        <Flex mb="20px">
          <Text color="darkPrimary" variant="title1">
            Otp verify
          </Text>
        </Flex>
        <Text color="darkPrimary">
          Votre code de confirmation a été envoyé à votre mail
        </Text>
        <Flex justifyContent="center">
          <Box>
            <OtpInput required={true} name={"otp"} />
          </Box>
        </Flex>
        {errorMessage && (
          <Text my="3px" color="stateError">
            {errorMessage}
          </Text>
        )}
        <Button
          isLoading={isLoadingVerifyOtp || isLoadingResendOtp}
          my="15px"
          w="full"
          type="submit"
        >
          Verify
        </Button>
        <Flex justifyContent="end" w="full">
          <Box cursor="pointer" onClick={() => handleResendOtp()}>
            <Text color="primary">Resend otp</Text>
          </Box>
        </Flex>
      </Form>
    </Formik>
  );
  const stepComponent = (currentStep: LoginStepEnum) => {
    switch (currentStep) {
      case LoginStepEnum.creditialStep:
        return CredentialStep;
      case LoginStepEnum.otpStep:
        return OtpStep;
      default:
        break;
    }
    return CredentialStep;
  };

  return (
    <Flex
      data-testid="Login"
      w="full"
      h="full"
      alignItems="center"
      justifyContent="center"
      p="20px"
    >
      <Box
        bg="white"
        borderRadius="7px"
        padding="50px"
        w={{ base: "400px", md: "500px" }}
        boxShadow="xl"
      >
        <HeaderAuth
          title="Welcome to Transfa"
          subTitle="No account ?"
          children={
            <Link color="primary" href={REGISTER}>
              Sign Up
            </Link>
          }
        />
        {stepComponent(step)}
      </Box>
    </Flex>
  );
}

export default Login;
