import type { TableProps } from "antd";
import { Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table/interface";
import { Box, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "store/index";
import {
  useGetApiV1CoreOrganizationByOrganizationPkWalletTransactionQuery,
  WalletTransaction,
} from "store/api/wallet";
import { useEffect, useState } from "react";
import { ELEMENT_PER_PAGE } from "constants/index";
import { formatDate } from "utils/helpers";
import SearchFilter from "components/Filters/SearchFilter";
import SelectFilter from "components/Filters/SelectFilter";

const WalletTransactions = () => {
  const organization = useSelector(
    (state: RootState) => state.organization.currentOrganization
  );
  const organizationId = !!organization ? organization.id : "";

  const [queryParams, setQueryParams] = useState<any>({});
  const [typeFilterValue, setTypeFilterValue] = useState<string | null>(null);

  const handleAddQueryParam = (param: any, value: any) => {
    const queryParam: any = {};
    queryParam[param] = value;
    setQueryParams({ ...queryParams, ...queryParam });
  };

  const handleTableChange: TableProps<WalletTransaction>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    // @ts-ignore
    switch (sorter.order) {
      case "descend":
        // @ts-ignore
        sorter.field && handleAddQueryParam("ordering", `-${sorter.field}`);
        break;
      case "ascend":
        // @ts-ignore
        sorter.field && handleAddQueryParam("ordering", `${sorter.field}`);
        break;
      default:
        setQueryParams({});
        break;
    }
  };

  const [page, setPage] = useState(1);
  const { data: walletTransaction, isFetching: isFetchingTransaction } =
    useGetApiV1CoreOrganizationByOrganizationPkWalletTransactionQuery({
      organizationPk: organizationId,
      page: page,
      ...queryParams,
    });

  const columns: ColumnsType<any> = [
    {
      title: "Reference",
      dataIndex: "reference",
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: true,
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (value: any) => {
        if (value == "debit") {
          return <Text color={"red"}>{value}</Text>;
        } else {
          return <Text color={"green"}>{value}</Text>;
        }
      },
    },
    {
      title: "Note",
      dataIndex: "note",
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: "Source",
      dataIndex: "source",
      render: (value) => <Text>{value}</Text>,
    },
    {
      title: "Date",
      dataIndex: "created",
      sorter: true,
      render: (value) => <Text>{formatDate(value)}</Text>,
    },
  ];

  const handlePageChange = async (pageNumber: number) => {
    setPage(pageNumber);
    window.scrollTo({ top: 70, behavior: "smooth" });
  };

  useEffect(() => {
    if (typeFilterValue) {
      handleAddQueryParam("type", typeFilterValue);
    } else {
      setQueryParams({});
    }
  }, [typeFilterValue]);

  return (
    <Box w="full">
      <Space style={{ marginBottom: 15, marginTop: 15 }}>
        <SearchFilter handleSearch={handleAddQueryParam} />
        <SelectFilter
          placeholder="Filter by type"
          options={[
            {
              value: "debit",
              label: "Debit",
            },
            {
              value: "credit",
              label: "Credit",
            },
          ]}
          value={typeFilterValue}
          handleFiltering={setTypeFilterValue}
        />
      </Space>
      <Table
        columns={columns}
        dataSource={walletTransaction?.results}
        loading={isFetchingTransaction}
        pagination={{
          onChange: handlePageChange,
          current: page,
          total: walletTransaction?.count,
          pageSize: ELEMENT_PER_PAGE,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />
    </Box>
  );
};

export default WalletTransactions;
