import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ChakraProvider } from "@chakra-ui/react";
import Theme from "theme";
import store, { persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <ChakraProvider theme={Theme}>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ToastContainer />
        <App />
      </PersistGate>
    </Provider>
  </ChakraProvider>,

  document.getElementById("root")
);
