import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organization } from "store/api/organization";

export type State = {
  currentOrganization: Organization | null;
};

const initialState: State = { currentOrganization: null };

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setCurrentOrganization(state: State, action: PayloadAction<Organization>) {
      state.currentOrganization = action.payload;
    },
  },
});

export default organizationSlice;
