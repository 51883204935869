const solidButtonVariants = {
  solid: {
    color: "white",
    bg: "primary",
    _hover: {
      bg: "radial-gradient(50% 50% at 50% 50%, #6F70EC 0%, rgba(111, 112, 236, 0.72) 100%)",
      textDecoration: "none",
      _disabled: {
        bg: "primary",
      },
    },
  },
};
export default solidButtonVariants;
