const defaultPalette = {
  primary: "#5000CA",
  darkPrimary: "#170F49",
  primary2: "#5A25D5",
  primary3: "#654BE1",
  primary4: "#6F70EC",
  primary5: "#7995F7",
  stateSuccess: "#0EB454",
  success2: "#EEFDFE",
  stateError: "#FF1D03",
  error2: "rgb(255 98 98 / 10%)",
  black: "#000000",
  blackDefault: "#3F404A",
  darkBlue: "#1E1932",
  gray: "#ADADAD",
  gray2: "#8E8E8E",
  gray3: "#808080",
  gray4: "#9D9FB1",
  gray5: "#A4A5B2",
  lightGray: "#F9F9F9",
};

export default defaultPalette;
