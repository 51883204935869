import InputField from "uikit/inputs/InputField";
import {
  Box,
  Button,
  Flex,
  HStack,
  Spacer,
  Text,
  VStack,
  Link as ChackraLink,
} from "@chakra-ui/react";
import { FORGOT_PASSWORD } from "constants/routing";

interface OwnerStepInterface {
  errorMessage: string;
  isLoading: boolean;
}

const OwnerStep = ({ errorMessage, isLoading }: OwnerStepInterface) => {
  return (
    <Box>
      <Flex mb="30px">
        <Text color="darkPrimary" variant="title1">
          Sign Up
        </Text>
      </Flex>

      <VStack>
        <HStack>
          <InputField label="First name" name="first_name" type="text" />
          <Spacer />
          <InputField label="Last name" name="last_name" type="text" />
        </HStack>
        <InputField label="Phone" name="phone" type="number" maxLength={8} />
        <InputField label="E-mail" name="email" type="email" />
        <InputField label="Password" name="password" type="password" />
      </VStack>
      {errorMessage && (
        <Text my="3px" color="stateError">
          {errorMessage}
        </Text>
      )}
      <Button isLoading={isLoading} my="15px" w="full" type="submit">
        Next Step
      </Button>
      <Flex justifyContent="end" my="10px" w="full">
        <Box>
          <ChackraLink color="primary" href={FORGOT_PASSWORD}>
            Forgot password
          </ChackraLink>
        </Box>
      </Flex>
    </Box>
  );
};

export default OwnerStep;
