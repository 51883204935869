import { createApi } from "@reduxjs/toolkit/query/react";
import {
  BaseQueryFn,
  fetchBaseQuery,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import authSlice from "store/slices/auth";
import { RootState } from "store";
import { TokenRefresh } from "./auth";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_TRANSFA_API_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as RootState).auth.access;
    if (token && endpoint.indexOf("Auth") == -1) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refresh = (api.getState() as RootState).auth.refresh;
    const refreshResult = await baseQuery(
      {
        url: "/api/v1/core/auth/refresh/",
        method: "POST",
        body: { refresh: refresh },
      },
      api,
      extraOptions
    );
    const refreshData = refreshResult.data as Partial<TokenRefresh>;
    if (!!refreshData?.access) {
      // store the new token
      api.dispatch(
        authSlice.actions.setAuthTokens({
          access: refreshData.access,
          refresh: refresh,
        })
      );
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      //  api.dispatch(loggedOut());
    }
  }
  return result;
};
// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: ["ApiKeyList"],
});
