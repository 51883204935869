import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "store/index";
interface ProtectedRoute {
  children: React.ReactElement;
  redirectPath: string;
}
const ProtectedRoute = ({
  children,
  redirectPath = "/login",
}: ProtectedRoute) => {
  const user = useSelector((state: RootState) => state.auth.user);
  if (!!user) {
    return children;
  }
  return <Navigate to={redirectPath} />;
};

export default ProtectedRoute;
