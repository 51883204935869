import { api } from "./index";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createApiV1CoreContactUs: build.mutation<
      CreateApiV1CoreContactUsApiResponse,
      CreateApiV1CoreContactUsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/core/contact_us/`,
        method: "POST",
        body: queryArg.contactUs,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as contact_us };
export type CreateApiV1CoreContactUsApiResponse = /** status 201  */ ContactUs;
export type CreateApiV1CoreContactUsApiArg = {
  contactUs: ContactUs;
};
export type ContactUs = {
  organization: string;
  email?: string;
  subject: string;
  message: string;
  created?: string;
};
export const { useCreateApiV1CoreContactUsMutation } = injectedRtkApi;
