import { Flex, Box, Text, Button } from "@chakra-ui/react";
import { Form, Input, Select } from "antd";
import { subjectList } from "configs/contactUs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "store";
import { useCreateApiV1CoreContactUsMutation } from "store/api/contactUs";
import { notify } from "store/slices/NotifyToastSlice";
import { hasError, ResponseQuery } from "utils/helpers";

const { TextArea } = Input;
const { Option } = Select;

type ContactUsDataType = {
  subject: string;
  message: string;
};
const initialValues: ContactUsDataType = { subject: "", message: "" };
const ContactUs = () => {
  const organisationId = useSelector(
    (state: RootState) => state.organization.currentOrganization?.id
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm<ContactUsDataType>();

  const [sendMessage, { isLoading }] = useCreateApiV1CoreContactUsMutation();
  const handleSendMessage = async (values: ContactUsDataType) => {
    try {
      const { created } = await sendMessage({
        contactUs: { ...values, organization: organisationId! },
      }).unwrap();
      if (created) {
        dispatch(
          notify({
            message: `Your message has been sent.`,
            typeToast: toast.TYPE.SUCCESS,
          })
        );
        form.resetFields();
      }
    } catch (err) {
      hasError({
        response: err as ResponseQuery,
        onError: () => {},
        displayToast: true,
      });
    }
  };

  return (
    <Flex justifyContent="center">
      <Box
        marginTop="70px"
        minWidth="500px"
        padding="50px"
        height="70%"
        minHeight="400px"
        borderRadius="7px"
      >
        <Form
          initialValues={initialValues}
          form={form}
          onFinish={(values) => handleSendMessage(values)}
        >
          <Flex direction="column" justifyContent="center">
            <Text
              variant="title"
              color="blackDefault"
              textAlign="center"
              marginY="10px"
            >
              Contact Us
            </Text>
            <Text>Subject</Text>
            <Form.Item
              name="subject"
              rules={[{ required: true, message: "Please select a subjet" }]}
            >
              <Select
                showSearch
                style={{ width: "40%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option!.children as unknown as string).includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA!.children as unknown as string)
                    .toLowerCase()
                    .localeCompare(
                      (optionB!.children as unknown as string).toLowerCase()
                    )
                }
              >
                {subjectList.map((subject) => (
                  <Option key={subject.hash} value={subject.hash}>
                    {subject.description}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Text>Message</Text>
            <Form.Item
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please, you need to write a message",
                },
              ]}
            >
              <TextArea rows={4} placeholder="Your message" />
            </Form.Item>
            <Button isLoading={isLoading} type="submit" mt="50px" w="full">
              Send
            </Button>
          </Flex>
        </Form>
      </Box>
    </Flex>
  );
};

export default ContactUs;
