import { ReactNode } from "react";
import {
  HStack,
  PinInput,
  PinInputField,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";

export type OtpInputDumbType = {
  placeholder?: string;
  name: string;
  defaultValue?: string;
  hasError?: boolean;
  label?: ReactNode;
  error?: string;
  variantTextLabel?: string;
  pinNumber?: number;
  onChange?: (val: string) => void;
};

const OtpInputDumb = ({
  defaultValue = "",
  name,
  hasError = false,
  label = "",
  error = "",
  variantTextLabel = "textSmallBold",
  pinNumber = 6,
  onChange,
}: OtpInputDumbType) => {
  return (
    <>
      <FormControl w="full" isInvalid={hasError}>
        <FormLabel htmlFor={name}>
          <Text color="darkPrimary" variant={variantTextLabel}>
            {label}
          </Text>
        </FormLabel>
        <HStack>
          <PinInput
            onChange={(e) => {
              if (!!e && !!onChange) {
                onChange(e);
              }
            }}
            defaultValue={defaultValue}
            placeholder="*"
          >
            {[...Array(pinNumber)].map((index) => (
              <PinInputField key={index}/>
            ))}
          </PinInput>
        </HStack>
        {hasError && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </>
  );
};
export default OtpInputDumb;
