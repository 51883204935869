import { Box, Flex, Text } from "@chakra-ui/react";

interface HeaderAuthInterface {
    title: string;
    subTitle: string;
    children:React.ReactNode
}

const HeaderAuth = ({
    title="Welcome to Transfa", 
    subTitle="No account ?", 
    children}:HeaderAuthInterface)=>{
    return(
        <Flex justifyContent="space-between">
            <Box>
              <Text color="darkPrimary" variant="textBodyBold">
                {title}
              </Text>
            </Box>
            <Box>
              <Text color="gray">{subTitle}</Text>
              <Text color="primary">
                {children}
              </Text>
            </Box>
        </Flex>
    )
}

export default HeaderAuth;